import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { QRCodeSVG } from 'qrcode.react';
import("./GenerateQRCode.css");

export default class GenerateQRCode extends Component {
    cssLoaded = false;
	constructor(props) {
		super(props);
		this.data = props.data;
		this.size = props.size;
	}

    render() {
		if (this.cssLoaded === false) {
			this.cssLoaded = true;
			import("./GenerateQRCode.css");
		}
        
		return (<QRCodeSVG value={this.data} size={this.size}/>);
    }
}