import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Header, Signature,  CashReconciliationListInfo } from "./CashReconciliationComponents";

export default class CashReconciliationReport extends Component {
	//isBankCopy = false;
	cssLoaded = false;
	constructor(props) {
		super(props);
		//this.isBankCopy = props.isBankCopy;
		this.reportData = props.result;
        this.isReprint = props.isReprint;
        //this.reportData.isSendReceipt = true;
        console.log(this.reportData);
	}
	render() {
		if (this.cssLoaded === false) {
			this.cssLoaded = true;
			import("./cashreconciliation.css");
		}
		return (
			<div className="content">
                    <table width="100%">
                        <tbody>
                            <tr>
                                <td width="100%" className="p-0 header-space"></td>
                            </tr>
                        </tbody>
                    </table>
                    <Header data={this.reportData} isReprint={this.isReprint}/>
					
                <table width="100%">
                    <tbody>
                        <tr>
                             <td width="100%" valign="top">
                                <CashReconciliationListInfo data={this.reportData} />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <Signature data={this.reportData} />
            </div>
		);
	}
}