import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import {
	DecimalToFixed,
	FormatAmount,
	TrimTime,
	FormatTransactionNumber,
} from "../../utils/helper";
import Barcode from "react-barcode";

const tdStyle = { direction: "ltr" };

function Header(props) {
	let headerText;
	let headerSubText;

	headerText = <b>Receipt / إيصال </b>;
	headerSubText = <b>Forex Reversal / إلغاء صرافة عملة </b>;

	let ReprintCode = <td width='35%' align='right' valign='middle'></td>;

	if (props.iscopy) {
		ReprintCode = (
			<td width='35%' align='right' valign='middle'>
				<p>
					<span className='w-25 text-start'>Reprint:</span>
					<span className='w-50 text-center'> {props.data.currentDate}</span>
					<span className='w-25 text-end'>:إعادة طباعة</span>
				</p>
			</td>
		);
	}

	return (
		<table width='100%' className='header'>
			<tbody>
				<tr>
					<td width='35%' align='left'>
						<p className='mb-0'>
							<span className='w-75 d-inlineblock valign-middle'>
								<Barcode
									value={props.data.transactionNumber}
									height={25}
									width={1}
									fontSize={10}
									margin={5}
								/>
							</span>
						</p>
					</td>
					<td align='center' valign='top'>
						<h4 style={{ marginTop: "10px" }}>{headerText}</h4>
						<h4 style={{ fontSize: "12px" }}>{headerSubText}</h4>
					</td>
					{ReprintCode}
				</tr>
				<tr>
					<td width='35%' align='left'>
						<p>
							<span className='w-25 text-start'>Trn No:</span>
							<span className='w-50 text-center'>
								<b> {props.data.transactionNumber} </b>
							</span>
							<span className='w-25 text-end'>العدد:</span>
						</p>
						<p>
							<span className='w-25 text-start'>Date:</span>
							<span className='w-50 text-center'>
								<b> {props.data.transactionDate} </b>
							</span>
							<span className='w-25 text-end'>التاريخ:</span>
						</p>
					</td>
					<td align='center' valign='top'></td>
					<td width='35%' align='right' valign='bottom'>
						<p>
							<span className='w-25 text-start'>Branch:</span>
							<span className='w-50 text-center'>
								<b> {props.data.branchName} </b>
							</span>
							<span className='w-25 text-end'>الفرع:</span>
						</p>
						<p>
							<span className='w-25 text-start'>Phone:</span>
							<span className='w-50 text-center'>
								<b> {props.data.branchPhone} </b>
							</span>
							<span className='w-25 text-end'>هاتف:</span>
						</p>
					</td>
				</tr>
			</tbody>
		</table>
	);
}

function FinancialInfo(props) {
	return (
	<table width='100%' className='border-0'>	
		<tbody>
			<tr>
				<td width="49%">
					<h3><span className="w-50">Original Transaction</span><span className="w-50 text-right">المعاملة الأصلية</span></h3>
					<span><span class="w-25 text-start">Type:</span><span class="w-50 text-center"><b> {props.data.originalType} </b></span><span class="w-25 text-end">نوع المعاملة:</span></span>
					<span><span class="w-25 text-start">Number:</span><span class="w-50 text-center"><b> {props.data.originalNumber} </b></span><span class="w-25 text-end">العدد:</span></span>
					<span><span class="w-25 text-start">Date:</span><span class="w-50 text-center"><b> {props.data.originalDate} </b></span><span class="w-25 text-end">التاريخ:</span></span>
					<span><span class="w-25 text-start">Customer:</span><span class="w-50 text-center"><b> {props.data.originalCustomer} </b></span><span class="w-25 text-end">الزبون:</span></span>
				</td>
				<td width='1%' align='center' style={{ position: "relative" }}>
					<p className='devider'></p>
				</td>
				<td width="49%">
					<h3><span className="w-50">Financial Information</span><span className="w-50 text-right">المعلومات المالية</span></h3>
					<span>
						<span class="w-25 text-start">Amount Collected:</span>
						<span class="w-50 text-center"></span>
						<span class="w-25 text-end">المبلغ المأخوذ:</span>
					</span>
					<CollectInfo data={props.data} />
					<span>
						<span class="w-25 text-start">Amount Given:</span>
						<span class="w-50 text-center"></span>
						<span class="w-25 text-end">المبلغ المسلم:</span>
					</span>
					<GivenInfo data={props.data} />	
				</td>
			</tr>
		</tbody>
	</table>
	);
}

function Signature(props) {
	return <div></div>;
}

function FooterInfo(props) {
	return <div></div>;
}

function CollectInfo(props) {
	if(props.data.flip) {
		return <span>
					<span class="w-25"></span>
					<span class="w-25">{props.data.baseCurrencyCode}</span>
					<span class="w-25 text-right">{props.data.totalText}</span>
					<span class="w-25"></span>
				</span>
	}
	else { 
		return <span>
		{props.data.currencyExDetailReportResponses.map(
			(item, index) => (
				<>
					<span class="w-25"></span>
					<span class="w-25">{item.forexCurrencyCode}</span>
					<span class="w-25 text-right">{item.forexCurrencyText}</span>
					<span class="w-25"></span>
				</>
			)
		)} </span>
	}
}

function GivenInfo(props) {		
	if(props.data.flip) { 
		return <span>
		{props.data.currencyExDetailReportResponses.map(
			(item, index) => (
				<>
					<span class="w-25"></span>
					<span class="w-25">{item.forexCurrencyCode}</span>
					<span class="w-25 text-right">{item.forexCurrencyText}</span>
					<span class="w-25"></span>
				</>
			)
		)} </span>
	}
	else {
		return <span>
					<span class="w-25"></span>
					<span class="w-25">{props.data.baseCurrencyCode}</span>
					<span class="w-25 text-right">{props.data.totalText}</span>
					<span class="w-25"></span>
				</span>
	}																				
}

export {
	Header,
	Signature,
	FooterInfo,
	FinancialInfo,
};
