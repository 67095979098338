import React, { Component } from "react";
import { Header, Signature, FooterInfo } from "./HeaderComponents";

export default class AgentReceipt extends Component {
	cssLoaded = false;
	constructor(props) {
		super(props);
		this.reportData = props.result;
		this.copyPrint = props.copyPrint;
	}
	render() {
		if (this.cssLoaded === false) {
			this.cssLoaded = true;
			import("./Exchange.css");
		}
        
		let approvedText = "Approved By:";
		let approvedBy = this.reportData.approvedByName;
		let requestNumber = "";
		let fromText = "From";
		let toText = "To";

		if (approvedBy == "System") {
			approvedText = "Approved";
			approvedBy = "";
		}

		if (this.reportData.requestNumber != '') {
			requestNumber = 
			<tr>
				<td>Request Number/ الرقم الطلب :</td>
				<td>
					<span>{this.reportData.requestNumber}</span>
				</td>
			</tr>;
		}

		if (this.reportData.serviceId == 21 || this.reportData.serviceId == 24) {
			fromText = "To";
			toText = "From";
		}

		return (
			<div className='content'>
				<table width='100%'>
					<tbody>
						<tr>
							<td width='100%' className='p-0 header-space'></td>
						</tr>
					</tbody>
				</table>
				<Header data={this.reportData} iscopy={this.copyPrint} />

				<table width='100%' className='border-0'>
					<tbody>
						<tr>
							<td width='100%'>
								<table width='100%' cellPadding='5' className='user-detail'>
									<thead>
									<tr>
											<th className="border-0"></th>
											<th className="text-center" colSpan={2}>From</th>
											<th className="border-0"></th>
											<th className="text-center" colSpan={2}>To</th>
										</tr>
										<tr>
											<th width='5%'>#</th>
											<th>Currency/العملة</th>
											<th>FCY Amount/المبلغ</th>
											<th className="text-center">Exchange Rate/سعر الصرف</th>
											<th>Currency/العملة</th>
											<th width='20%' className='text-right'>
												LCY Amount/المبلغ
											</th>
										</tr>
									</thead>
									<tbody>
										{this.reportData.currencyExDetailReportResponses.map(
											(item, index) => (
												<tr key={index}>
													<td>{index + 1}</td>
													<td className='centre'>{item.forexCurrencyCode}</td>
													<td className='right'>{item.forexCurrencyText}</td>
													<td className='right'>{item.inverseRateText} / {item.rateText}</td>
													<td>{this.reportData.baseCurrencyCode}</td>
													<td className='right'>
														{item.totalText}
													</td>
												</tr>
											)
										)}
									</tbody>
								</table>
							</td>
						</tr>
						<tr>
							<td width='100%' height='20'>

							</td>
						</tr>
						<tr>
							<td width='100%' className='padding-0'>
								<table width='100%'>
									<tbody>
										<tr>
											<td align='left' className='border-0' width='70%'>
												<table width='100%'>
													<tbody>
														<tr>
															<td width="40%">Agent/ الوكيل :</td>
															<td>
																<span>{this.reportData.agentName}</span>
															</td>
														</tr>
														<tr>
															<td>Account No./ رقم حساب :</td>
															<td>
																<span>{this.reportData.accountNumber}</span>
															</td>
														</tr>
														<tr>
															<td>Requested By/طلب من :</td>
															<td>
																<span>{this.reportData.requestedByName}</span>
															</td>
														</tr>
														{requestNumber}
														<tr>
															<td>Signature/التوقيع :</td>
															<td>
																<span
																	style={{ borderBottom: "1px solid", display: "inline-block", minWidth: "150px" 	}}
																></span>
															</td>
														</tr>
														<tr>
															<td>{approvedText}</td>
															<td>
																<span>{approvedBy}</span>
															</td>
														</tr>
													</tbody>
												</table>
											</td>
											{/* <td align='left' className='border-0'>
												<table width='100%'>
													<tbody>
														<tr>
															<td>Cashier/ أمين الصندوق :</td>
															<td>
																<span>{this.reportData.cashierName}</span>
															</td>
														</tr>
														<tr>
															<td>Signature/التوقيع :</td>
															<td>
																<span
																	style={{ textDecoration: "underline" }}
																></span>
															</td>
														</tr>
													</tbody>
												</table>
											</td> */}
											<td align='left' className='right'>
												<table width='100%' className='totals'>
													<tbody>
														{this.reportData.serviceFeeBreakdownResponses.map(
															(item, index) => (
																<tr key={index}>
																	<td></td>
																	<td width='40%' className='text-right'>
																		{item.feeLabel} :
																	</td>
																	<td width='20%'>
																		<span>{item.amountText}</span>
																	</td>
																</tr>
															)
														)}
														{/* <tr>
															<td className="border1-0"></td>
															<td className="border1-0 text-left">Rounding :</td>
															<td className="border1-0">
																<span>-</span>
															</td>
														</tr> */}
														<tr>
															<td></td>
															<td class="text-left">Total :</td>
															<td>
																<span>
																	{this.reportData.baseCurrencyCode}{" "}
																	{this.reportData.totalText}{" "}
																</span>
															</td>
														</tr>
													</tbody>
													<tfoot>
														<tr>
															<td style={{ borderTop: "0 !importamt" }}></td>
															<td
																width=''
																colSpan='2'
																style={{
																	border: "1px solid",
																	padding: 0,
																	whiteSpace: "nowrap",
																}}
															>
																{this.reportData.totalInWord}
															</td>
														</tr>
													</tfoot>
												</table>
											</td>
										</tr>
									</tbody>
								</table>
							</td>
						</tr>
					</tbody>
				</table>

				<table width='100%' className='footer' style={{ borderTop: 0 }}>
					<tbody>
						<tr>
							<td width='100%' className='p-0 footer-space'></td>
						</tr>
						<tr>
							<td width='100%' className='p-0' height='5'></td>
						</tr>
					</tbody>
				</table>
			</div>
		);
	}
}
