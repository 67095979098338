import React, { Component } from 'react';
import PaymentReversalReceipt from '../components/Remittance/PaymentReversalReceipt';
import { getRemittanceReportData } from '../services/ReportService';

export default class PaymentReversalRemittanceReceipt extends Component {

  constructor(){
    super();
    this.language = 'en';
    var count = window.location.pathname.split('/').length; 
    this.tid = window.location.pathname.split('/')[2];
    this.isReprintData = false;
    var reprint = window.location.search; 
    if (reprint == ''){
        this.isReprintData = false;
    }else{
      this.isReprintData = true;
    }
    this.state = { reportData: null };
  }
  componentDidMount() {

    getRemittanceReportData(this.tid, this.language).then((res) => {
      this.setState({reportData : res.data});
    });
  }
  render() {
    return (
      this.state.reportData ?
        <div>
          <PaymentReversalReceipt result={this.state.reportData} isBankCopy={false} isReprint={this.isReprintData}/>
          <PaymentReversalReceipt result={this.state.reportData} isBankCopy={true} isReprint={this.isReprintData}/>
        </div>
        : <div></div>
    );
  }
}
