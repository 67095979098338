import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import {
	Header,
	Signature,
	SenderInfo,
	BenificiaryInfo,
	OtherInfo,
	FinancialInfo,
} from "./ReceiptComponents";

export default class SendReceipt extends Component {
	isBankCopy = false;
	cssLoaded = false;
	showConsent = true;
	constructor(props) {
		super(props);
		this.isBankCopy = props.isBankCopy;
		this.reportData = props.result;
		this.reportData.isSendReceipt = true;
		this.isReprint = props.isReprint;
		this.showConsent = props.result.showConsent;
		//console.log(this.reportData);
		document.title = "Send_Remittance_Receipt_" + props.result.transactionNumber;
	}
	render() {
		if (this.cssLoaded === false) {
			this.cssLoaded = true;
			import("./Remittance.css");
		}

let consent;
if (this.showConsent === true) {	
	consent = <p style= {{ fontSize: "10px",  marginBottom: "0" }}>I consent to receive communications related to this transaction</p>;
}

		return (
			<div className='content'>
				<table width='100%'>
					<tbody>
						<tr>
							<td width='100%' className='p-0 header-space'></td>
						</tr>
					</tbody>
				</table>
				<Header data={this.reportData} isReprint={this.isReprint} />

				<table width='100%'>
					<tbody>
						<tr>
							<td width='55%' valign='top'>
								<table width='100%' cellPadding='0' cellSpacing='0'>
									<tbody>
										<SenderInfo data={this.reportData} />
										<BenificiaryInfo data={this.reportData} />
										<OtherInfo data={this.reportData} />
									</tbody>
								</table>
							</td>
							<td width='1%' align='center' style={{ position: "relative" }}>
								<p className='devider'></p>
							</td>
							<td width='44%' valign='top'>
								<FinancialInfo data={this.reportData} />
							</td>
						</tr>
					</tbody>
				</table>
				<Signature data={this.reportData} />
				{consent}
			</div>
		);
	}
}
