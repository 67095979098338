function DecimalToFixed(num, dp) {
	if (num === null || num === undefined) {
		return 0;
	}

	return Number.parseFloat(num).toFixed(dp);
}

function FormatAmount(amount) {
	if (amount == null) {
		return amount;
	}

	var parts = amount.toString().split(".");

	const numberPart = parts[0];
	const decimalPart = parts[1];
	const thousands = /\B(?=(\d{3})+(?!\d))/g;

	return (
		numberPart.replace(thousands, ",") + (decimalPart ? "." + decimalPart : "")
	);
	//return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function TrimTime(date) {
	if (date !== null && date !== undefined) {
		return date.substring(0, 10);
	}

	return date;
}

function FormatTransactionNumber(tranid) {
	return tranid.replace(/[-]/g, "/");
}
export { DecimalToFixed, FormatAmount, TrimTime, FormatTransactionNumber };
