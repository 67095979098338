import React, { Component } from 'react';
import { getImportRemittanceReportData } from '../services/ReportService';
import ImportRemittanceReceipt from '../components/Remittance/ImportRemittanceReceipt';

export default class ImportRemittancesReceipt extends Component {

  constructor(){
    super();
    this.language = 'en';
    this.tid = window.location.pathname.split('/')[2];
    this.isReprintData = false;
    var reprint = window.location.search; 
    if (reprint == ''){
        this.isReprintData = false;
    }else{
      this.isReprintData = true;
    }
    this.state = { reportData: null };
  }
  componentDidMount() {
    getImportRemittanceReportData(this.tid, this.language).then((res) => {
      this.setState({reportData : res.data});
    });
  }
  render() {
    return (
      this.state.reportData ?
        <div>
          <ImportRemittanceReceipt result={this.state.reportData} isBankCopy={false} isReprint={this.isReprintData}/>
          <ImportRemittanceReceipt result={this.state.reportData} isBankCopy={true} isReprint={this.isReprintData}/>
        </div>
        : <div></div>
    );
  }
}
