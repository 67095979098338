import React, { Component } from "react";
import HomePage from "./pages/Home";
import ExchangeReceipt from "./pages/ExchangeReceipt";
import AgentExchangeReceipt from "./pages/AgentExchangeReceipt";
import BulkExchangeReceipt from "./pages/BulkExchangeReceipt";
import AgentExchangeRequestReceipt from "./pages/AgentExchangeRequestReceipt";
import SendRemittanceReceipt from "./pages/SendRemittanceReceipt";
import ImportRemittancesReceipt from "./pages/ImportRemittancesReceipt";
import RefundRemittanceReceipt from "./pages/RefundRemittanceReceipt";
import PaymentReversalRemittanceReceipt from "./pages/PaymentReversalRemittanceReceipt";
import PayRemittanceReceipt from "./pages/PayRemittanceReceipt";
import ReverseRemittanceReceipt from "./pages/ReverseRemittanceReceipt";
import ModifyRemittanceReceipt from "./pages/ModifyRemittanceReceipt";
import InternalCashMoveReceipt from "./pages/InternalCashMoveReceipt";
import CashReconciliationReport from "./pages/CashReconciliationReport";
import AuctionExchangeRecipet from "./pages/AuctionExchangeRecipet";
import TransactionReversalReceipt from "./pages/TransactionReversalReceipt";
import CashReceipt from "./pages/CashReceipt";
import { IntlProvider } from "react-intl";
import English from "./lang/en-US.json";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

export default class App extends Component {
	render() {
		var language = "en";
		// We can use the `useParams` hook here to access
		// the dynamic pieces of the URL.
		const locale = language;

		let culture = English;
		return (
			<div style={{ height: "100%" }}>
				<Router>
					<Routes>
						<Route
							exact
							path='/'
							element={
								<React.StrictMode>
									<HomePage />
									<Apps />
								</React.StrictMode>
							}
						></Route>
						<Route
							path='/exchangereceipt'
							element={
								<React.StrictMode>
									<IntlProvider locale={locale} messages={culture}>
										<ExchangeReceipt />
									</IntlProvider>
								</React.StrictMode>
							}
						></Route>
						<Route
							path='/agentexchangerequest'
							element={
								<React.StrictMode>
									<IntlProvider locale={locale} messages={culture}>
										<AgentExchangeRequestReceipt />
									</IntlProvider>
								</React.StrictMode>
							}
						></Route>
						<Route
							path='/agentexchangereceipt'
							element={
								<React.StrictMode>
									<IntlProvider locale={locale} messages={culture}>
										<AgentExchangeReceipt />
									</IntlProvider>
								</React.StrictMode>
							}
						></Route>
						<Route
							path='/bulkexchangereceipt'
							element={
								<React.StrictMode>
									<IntlProvider locale={locale} messages={culture}>
										<BulkExchangeReceipt />
									</IntlProvider>
								</React.StrictMode>
							}
						></Route>
						<Route
							path='/auctionexchangereceipt'
							element={
								<React.StrictMode>
									<IntlProvider locale={locale} messages={culture}>
										<AuctionExchangeRecipet />
									</IntlProvider>
								</React.StrictMode>
							}
						></Route>
						<Route
							path='/cashreceipt'
							element={
								<React.StrictMode>
									<IntlProvider>
										<CashReceipt />
									</IntlProvider>
								</React.StrictMode>
							}
						></Route>
						<Route
							path='/sendremittancereceipt/:tid'
							element={
								<React.StrictMode>
									<IntlProvider locale={locale} messages={culture}>
										<SendRemittanceReceipt />
									</IntlProvider>
								</React.StrictMode>
							}
						></Route>
						<Route
							path='/sendremittancereceipt/:tid/:reprint'
							element={
								<React.StrictMode>
									<IntlProvider locale={locale} messages={culture}>
										<SendRemittanceReceipt />
									</IntlProvider>
								</React.StrictMode>
							}
						></Route>
						<Route
							path='/importremittancesreceipt/:tid'
							element={
								<React.StrictMode>
									<IntlProvider locale={locale} messages={culture}>
										<ImportRemittancesReceipt />
									</IntlProvider>
								</React.StrictMode>
							}
						></Route>
						<Route
							path='/importremittancesreceipt/:tid/:reprint'
							element={
								<React.StrictMode>
									<IntlProvider locale={locale} messages={culture}>
										<ImportRemittancesReceipt />
									</IntlProvider>
								</React.StrictMode>
							}
						></Route>
						<Route
							path='/RefundRemittanceReceipt/:tid'
							element={
								<React.StrictMode>
									<IntlProvider locale={locale} messages={culture}>
										<RefundRemittanceReceipt />
									</IntlProvider>
								</React.StrictMode>
							}
						></Route>
						<Route
							path='/RefundRemittanceReceipt/:tid/:reprint'
							element={
								<React.StrictMode>
									<IntlProvider locale={locale} messages={culture}>
										<RefundRemittanceReceipt />
									</IntlProvider>
								</React.StrictMode>
							}
						></Route>
						<Route
							path='/PaymentReversalRemittanceReceipt/:tid'
							element={
								<React.StrictMode>
									<IntlProvider locale={locale} messages={culture}>
										<PaymentReversalRemittanceReceipt />
									</IntlProvider>
								</React.StrictMode>
							}
						></Route>
						<Route
							path='/PaymentReversalRemittanceReceipt/:tid/:reprint'
							element={
								<React.StrictMode>
									<IntlProvider locale={locale} messages={culture}>
										<PaymentReversalRemittanceReceipt />
									</IntlProvider>
								</React.StrictMode>
							}
						></Route>
						<Route
							path='/payremittancereceipt/:tid'
							element={
								<React.StrictMode>
									<IntlProvider locale={locale} messages={culture}>
										<PayRemittanceReceipt />
									</IntlProvider>
								</React.StrictMode>
							}
						></Route>
						<Route
							path='/payremittancereceipt/:tid/:reprint'
							element={
								<React.StrictMode>
									<IntlProvider locale={locale} messages={culture}>
										<PayRemittanceReceipt />
									</IntlProvider>
								</React.StrictMode>
							}
						></Route>
						<Route
							path='/reverseremittancereceipt/:tid'
							element={
								<React.StrictMode>
									<IntlProvider locale={locale} messages={culture}>
										<ReverseRemittanceReceipt />
									</IntlProvider>
								</React.StrictMode>
							}
						></Route>
						<Route
							path='/reverseremittancereceipt/:tid/:reprint'
							element={
								<React.StrictMode>
									<IntlProvider locale={locale} messages={culture}>
										<ReverseRemittanceReceipt />
									</IntlProvider>
								</React.StrictMode>
							}
						></Route>
						<Route
							path='/modifyremittancereceipt/:tid'
							element={
								<React.StrictMode>
									<IntlProvider locale={locale} messages={culture}>
										<ModifyRemittanceReceipt />
									</IntlProvider>
								</React.StrictMode>
							}
						></Route>
						<Route
							path='/modifyremittancereceipt/:tid/:reprint'
							element={
								<React.StrictMode>
									<IntlProvider locale={locale} messages={culture}>
										<ModifyRemittanceReceipt />
									</IntlProvider>
								</React.StrictMode>
							}
						></Route>
						<Route
							path='/internalcashmovereceipt/:cashmoveid'
							element={
								<React.StrictMode>
									<IntlProvider locale={locale} messages={culture}>
										<InternalCashMoveReceipt />
									</IntlProvider>
								</React.StrictMode>
							}
						></Route>
						<Route
							path='/cashreconciliationreport/:tranid/:lang'
							element={
								<React.StrictMode>
									<IntlProvider locale={locale} messages={culture}>
										<CashReconciliationReport />
									</IntlProvider>
								</React.StrictMode>
							}
						></Route>
						<Route
							path='/cashreconciliationreport/:tranid'
							element={
								<React.StrictMode>
									<IntlProvider locale={locale} messages={culture}>
										<CashReconciliationReport />
									</IntlProvider>
								</React.StrictMode>
							}
						></Route>						
						<Route
						path='/transactionreversalreceipt'
						element={
							<React.StrictMode>
								<IntlProvider locale={locale} messages={culture}>
									<TransactionReversalReceipt />
								</IntlProvider>
							</React.StrictMode>
						}
					></Route>
					</Routes>
				</Router>
			</div>
		);
	}
}

function ReceiptElement() {
	var language = "en";
	// We can use the `useParams` hook here to access
	// the dynamic pieces of the URL.
	const locale = language;

	let culture = English;
	return (
		<Router>
			<Routes>
				<Route
					exact
					path='/'
					element={
						<React.StrictMode>
							<HomePage />
							<Apps />
						</React.StrictMode>
					}
				></Route>
				<Route
					path='/exchangereceipt'
					element={
						<React.StrictMode>
							<IntlProvider locale={locale} messages={culture}>
								<ExchangeReceipt />
							</IntlProvider>
						</React.StrictMode>
					}
				></Route>
				<Route
					path='/agentexchangerequest'
					element={
						<React.StrictMode>
							<IntlProvider locale={locale} messages={culture}>
								<AgentExchangeRequestReceipt />
							</IntlProvider>
						</React.StrictMode>
					}
				></Route>
				<Route
					path='/agentexchangereceipt'
					element={
						<React.StrictMode>
							<IntlProvider locale={locale} messages={culture}>
								<AgentExchangeReceipt />
							</IntlProvider>
						</React.StrictMode>
					}
				></Route>
				<Route
					path='/bulkexchangereceipt'
					element={
						<React.StrictMode>
							<IntlProvider locale={locale} messages={culture}>
								<BulkExchangeReceipt />
							</IntlProvider>
						</React.StrictMode>
					}
				></Route>
				<Route
					path='/auctionexchangereceipt'
					element={
						<React.StrictMode>
							<IntlProvider locale={locale} messages={culture}>
								<AgentExchangeReceipt />
							</IntlProvider>
						</React.StrictMode>
					}
				></Route>
				<Route
					path='/cashreceipt'
					element={
						<React.StrictMode>
							<IntlProvider>
								<CashReceipt />
							</IntlProvider>
						</React.StrictMode>
					}
				></Route>
				<Route
					path='/sendremittancereceipt/:tid'
					element={
						<React.StrictMode>
							<IntlProvider locale={locale} messages={culture}>
								<SendRemittanceReceipt />
							</IntlProvider>
						</React.StrictMode>
					}
				></Route>
				<Route
					path='/sendremittancereceipt/:tid/:reprint'
					element={
						<React.StrictMode>
							<IntlProvider locale={locale} messages={culture}>
								<SendRemittanceReceipt />
							</IntlProvider>
						</React.StrictMode>
					}
				></Route>
				<Route
					path='/importremittancesreceipt/:tid'
					element={
						<React.StrictMode>
							<IntlProvider locale={locale} messages={culture}>
								<ImportRemittancesReceipt />
							</IntlProvider>
						</React.StrictMode>
					}
				></Route>
				<Route
					path='/importremittancesreceipt/:tid/:reprint'
					element={
						<React.StrictMode>
							<IntlProvider locale={locale} messages={culture}>
								<ImportRemittancesReceipt />
							</IntlProvider>
						</React.StrictMode>
					}
				></Route>
				<Route
					path='/RefundRemittanceReceipt/:tid'
					element={
						<React.StrictMode>
							<IntlProvider locale={locale} messages={culture}>
								<RefundRemittanceReceipt />
							</IntlProvider>
						</React.StrictMode>
					}
				></Route>
				<Route
					path='/RefundRemittanceReceipt/:tid/:reprint'
					element={
						<React.StrictMode>
							<IntlProvider locale={locale} messages={culture}>
								<RefundRemittanceReceipt />
							</IntlProvider>
						</React.StrictMode>
					}
				></Route>
				<Route
					path='/payremittancereceipt/:tid'
					element={
						<React.StrictMode>
							<IntlProvider locale={locale} messages={culture}>
								<PayRemittanceReceipt />
							</IntlProvider>
						</React.StrictMode>
					}
				></Route>
				<Route
					path='/payremittancereceipt/:tid/:reprint'
					element={
						<React.StrictMode>
							<IntlProvider locale={locale} messages={culture}>
								<PayRemittanceReceipt />
							</IntlProvider>
						</React.StrictMode>
					}
				></Route>
				<Route
					path='/modifyremittancereceipt/:tid'
					element={
						<React.StrictMode>
							<IntlProvider locale={locale} messages={culture}>
								<ModifyRemittanceReceipt />
							</IntlProvider>
						</React.StrictMode>
					}
				></Route>
				<Route
					path='/modifyremittancereceipt/:tid/:reprint'
					element={
						<React.StrictMode>
							<IntlProvider locale={locale} messages={culture}>
								<ModifyRemittanceReceipt />
							</IntlProvider>
						</React.StrictMode>
					}
				></Route>
				<Route
					path='/internalcashmovereceipt/:cashmoveid'
					element={
						<React.StrictMode>
							<IntlProvider locale={locale} messages={culture}>
								<InternalCashMoveReceipt />
							</IntlProvider>
						</React.StrictMode>
					}
				></Route>
				<Route
					path='/cashreconciliationreport/:tranid/:lang'
					element={
						<React.StrictMode>
							<IntlProvider locale={locale} messages={culture}>
								<CashReconciliationReport />
							</IntlProvider>
						</React.StrictMode>
					}
				></Route>
			</Routes>
		</Router>
	);
}
const Apps = () => {
	return;
};
