import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { DecimalToFixed, FormatAmount, TrimTime } from "../../utils/helper";
import Barcode from "react-barcode";

function Header(props) {  

    let ReprintCode = <td width="35%" align="right" valign="top"></td>;
    
    if (props.isReprint)
    {
        ReprintCode = <td width="35%" align="left" valign="middle">
                        <span className="w-15 text-start">Reprint:</span>
                        <span className="w-70 text-center"><b>{props.data.printDate}</b></span>
                        <span className="w-15 text-end text-rtl">:إعادة طباعة</span>
                      </td>;
    }

    return (
        <div>
            <table width="100%" className="header">
                <tbody> 
                    <tr>
                        <td width="35%" align="left">
                            <p className="mb-0">
                                <span className="w-75 d-inlineblock valign-middle">
                                    <Barcode
                                            value={props.data.cashMoveTranNum}
                                            height={25}
                                            width={1}
                                            fontSize={10}
                                            margin={5}
                                    />
                                </span>
                            </p>
                        </td> 
                        <td align="center" valign="top">
                            <h4>
                                <b className="d-block"> سند قيد داخلي </b>
                                <b>Cash Move Receipt</b>
                            </h4>                        
                        </td>
                        {ReprintCode}
                    </tr>
                </tbody>
            </table>

            <table width="100%" className="header">
                <tbody> 
                    <tr>
                        <td width="45%" align="left" valign="top">
                            <span className="w-25 text-start">Receipt No:</span>
                            <span className="w-50 text-center"><b> {props.data.cashMoveTranNum} </b></span>
                            <span className="w-25 text-end">:العدد</span>
                    
                            <span className="w-20 text-start">Date:</span>
                            <span className="w-60 text-center"><b> {props.data.cashMoveDateTime} </b></span>
                            <span className="w-20 text-end">:التاريخ</span>                   
                        </td>

                        <td align="center" valign="top"></td>

                        <td width="45%" align="left">                                                                        
                            <span className="w-25 text-start">Branch:</span>
                            <span className="w-50 text-center"><b> {props.data.branchName} </b></span>
                            <span className="w-25 text-end">
                                :الفرع
                            </span>
                    
                            <span className="w-25 text-start">Oraganizer:</span>
                            <span className="w-50 text-center"><b> {props.data.cashMovedByUserName} </b></span>
                            <span className="w-25 text-end">
                                :المنظم 
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td width="45%" align="right" valign="top"></td>
                        <td align="center" valign="top"></td>
                        <td width="45%" align="left" valign="top">                      
                            <span className="w-25 text-start">Move Cash From:</span>
                            <span className="w-50 text-center"><b> {props.data.moveCashFrom} </b></span>
                            <span className="w-25 text-end">:الحساب الدائن</span>
                                    
                            <span className="w-25 text-start">Move Cash To:</span>
                            <span className="w-50 text-center"><b> {props.data.moveCashTo} </b></span>
                            <span className="w-25 text-end">:الحساب المدين</span>                      
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

function Signature(props) {
    return (
        <table width="100%">
            <tbody>
                <tr>
                    <td></td>
                </tr>
                <tr>
                    <td width="45%">
                        <p> Moved From Signature / توقيع المقبوض منه 
                            <span></span>
                        </p>
                    </td>
                    <td width="55%" valign="top">
                        <p>                          
                            <span className="w-50">Moved To Signature / توقيع المدفوع له:
                                <b></b>
                            </span>
                        </p>
                    </td>
                </tr>
                
            </tbody>
        </table>
    );
}

function CashMoveListInfo(props) {
    return (
        <table width="100%" cellPadding="0" cellSpacing="0">
            <tbody>
                <tr>
                    <td width="100%" className="p-0">
                        <h3>
                            <span className="w-50">Cash Movement Details</span>
                            <span className="w-50 text-right">
                            تفاصيل تناقل النقد
                            </span>
                        </h3>
                    </td>
                </tr>
                <tr>
                    <td width="100%" className="p-0">
                    <table width="100%" cellPadding="0" cellSpacing="0" className="financial-info">
                            <thead>
							<tr>
                                    <td width="5%" align="left">
                                        <b>#</b>
                                    </td>
                                    <td width="15%" align="left"><b>المبلغ / Amount</b></td>
                                    <td width="15%" align="left" ><b>العملة / Currency</b></td>
                                    <td width="25%" align="left"><b>المبلغ كتابة / Amount in Words</b></td>
                                </tr>
                                </thead>
                                <tbody>
										{props.data.cashMoveDetails.map(
											(item, index) => (
												<tr key={index}>
													<td>{index + 1}</td>
													<td>{item.cashMoveAmount}</td>
													<td>{item.cashMoveCurrency}</td>
													<td>{item.amountInWords}</td>													
												</tr>
											)
										)}
                                <tr><td colSpan="4" width="100%" height="25"></td></tr>
                                <tr>
                                    <td className="text-center" style={{border: "solid 1px #ccc",whiteSpace:"nowrap",height:"50px"}}>
                                    ملاحظات / Comments:
                                    </td>
                                    <td colSpan="3" style={{border: "solid 1px #ccc",height:"50px" }}>
                                    </td>
                                </tr>
                                </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
    );
}

export { Header, Signature,  CashMoveListInfo };