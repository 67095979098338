import React, { Component } from "react";
import Receipt from "../components/Exchange/Receipt";
import querySearch from "stringquery";

import { getExchangeTransaction } from "../services/ReportService";

export default class ExchangeReceipt extends Component {
	constructor() {
		super();
		this.state = { reportData: null };
	}
	componentDidMount() {
		const query = querySearch(window.location.search);
		getExchangeTransaction(query.tid, query.lang).then((res) => {
			this.setState({ reportData: res.data, urldata: query.v });
			document.title = "Exchange_Receipt_" + res.data.transactionNumber;
		});
	}
	render() {
		return this.state.reportData ? (
			<div style={{ height: "100%" }}>
				<Receipt
					result={this.state.reportData}
					isBankCopy={false}
					copyPrint={this.state.urldata}
				/>
				<Receipt result={this.state.reportData} isBankCopy={true} />
			</div>
		) : (
			<div></div>
		);
	}
}
