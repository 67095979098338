import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Header, Signature, SenderInfo, ReverseFinancialInfo } from "./ReceiptComponents";

export default class ReverseReceipt extends Component {
	isBankCopy = false;
	cssLoaded = false;
	constructor(props) {
		super(props);
		this.isBankCopy = props.isBankCopy;
		this.reportData = props.result;
        this.reportData.isReverseReceipt = true;
		this.isReprint = props.isReprint;
        //console.log(this.reportData);
        document.title = "Reverse_Remittance_Receipt_" + props.result.transactionNumber;
	}
	render() {
		if (this.cssLoaded === false) {
			this.cssLoaded = true;
			import("./Remittance.css");
		}
		return (
			<div className="content">
                    <table width="100%">
                        <tbody>
                            <tr>
                                <td width="100%" className="p-0 header-space"></td>
                            </tr>
                        </tbody>
                    </table>
                    <Header data={this.reportData} isReprint={this.isReprint}/>
					
                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="55%" valign="top">
                                <table width="100%" cellPadding="0" cellSpacing="0">
                                    <tbody>
                                        <SenderInfo data={this.reportData} />
                                    </tbody>
                                </table>
                            </td>
                            <td width="1%" align="center" style={{ position: "relative" }}><p className="devider"></p></td>
                            <td width="44%" valign="top">
                                <table width="100%" cellPadding="0" cellSpacing="0">
                                    <tbody>
                                        <ReverseFinancialInfo data={this.reportData} />
                                    </tbody>                                        
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <Signature data={this.reportData} />
            </div>
		);
	}
}