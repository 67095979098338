import React, { Component } from "react";
import { FormatAmount } from "../../utils/helper";
import {
	Header,
	CustomerInfo,
	ClientInfo,
	FinancialInfo,
} from "./HeaderComponents";
import { Signature } from "../Remittance/ReceiptComponents";

export default class ReversalReceipt extends Component {
	cssLoaded = false;
	constructor(props) {
		super(props);
		this.reportData = props.result;
		this.copyPrint = props.copyPrint;
	}
	render() {
		if (this.cssLoaded === false) {
			this.cssLoaded = true;
			import("./Cash.css");
		}
		return (
			<div className='content'>
				<table width='100%'>
					<tbody>
						<tr>
							<td width='100%' className='p-0 header-space'></td>
						</tr>
					</tbody>
				</table>
				<Header data={this.reportData} isReprint={this.isReprint} />

				<table width='100%'>
					<tbody>
						<tr>
							<td width='44%' valign='top'>
								<FinancialInfo data={this.reportData} />
							</td>
						</tr>
					</tbody>
				</table>
				<Signature data={this.reportData} />
			</div>
		);
	}
}
