import React, { Component } from "react";
import Index from "../components/Home/Index";
// import { getExchangeTransaction } from "../services/ReportService";

export default class HomePage extends Component {
	// constructor() {
	// 	super();
	// }
	// componentDidMount() {
	// 	getExchangeTransaction("010000003284", "en").then((res) => {
	// 		this.setState({ reportData: res.data });
	// 	});
	// }
	render() {
		return (
			<div>
				<Index />
			</div>
		);
	}
}
