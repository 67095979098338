import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { DecimalToFixed, FormatAmount, TrimTime, FormatTransactionNumber } from "../../utils/helper";
import GenerateQRCode from "../QRCode/GenerateQRCode";
import Barcode from "react-barcode";
import logo from '../../logo.svg';
function Header(props) {
    let headerText;
    let headerSubText;

    if(props.data.isSendReceipt){
        headerText = <b>Receipt - إيصال </b>;
        headerSubText = <b>Create Remittance - إرسال حوالة </b>
    }
    if(props.data.isPayReceipt){
        headerText = <b>Receipt - إيصال </b>;
        headerSubText = <b>Pay Remittance - دفع حوالة </b>;
    }
    if(props.data.isReverseReceipt){
        headerText = <b>Receipt - إيصال </b>;
        headerSubText = <b>Reverse Remittance - رد حوالة </b>;
    }
    if(props.data.isRefundReceipt){
        headerText = <b>Receipt - إيصال </b>;
        headerSubText = <b>REFUND REM REQUEST - طلب إلغاء حوالة</b>
    }
    if(props.data.isModifyReceipt){
        headerText = <b>Receipt - إيصال </b>;
        headerSubText = <b>MODIFY REM REQUEST - طلب تعديل حوالة</b>
    }
    if(props.data.isPaymentReversalReceipt){
        headerText = <b>Receipt - إيصال </b>;
        headerSubText = <b>REM PAYOUT REVERSAL REQUEST - طلب رد دفع حوالة</b>
    }
    if(props.data.isImportRemittanceReceipt){
        headerText = <b>Receipt - إيصال </b>;
        headerSubText = <b>IMPORT REMITTANCES - استيراد الحوالات</b>
    }
    let ReprintCode = <td width="35%" align="right" valign="middle"></td>;
    
    if (props.isReprint){
        ReprintCode = <td width="35%" align="right" valign="middle">
        <p>
            <span className="w-25 text-start">Reprint:</span>
            <span className="w-50 text-center"> {props.data.currentDate}</span>
            <span className="w-25 text-end">
                :إعادة طباعة
            </span>
        </p>
    </td>;
    }

    return (
        <table width="100%" className="header">
            <tbody>
            <tr>
                <td width="35%" align="left">
                        <p className="mb-0">
                    <span className="w-75 d-inlineblock valign-middle" style={{height:"37.4px"}}>
                        <Barcode
                                value={props.data.transactionNumber}
                                height={25}
                                width={1}
                                fontSize={10}
                                margin={5}
                        />
                    </span>
                        </p>
                    </td>
                    <td align="center" valign="top">
                        {/* <h4 style={{marginTop:"10px"}}>
                            {headerText}
                        </h4>
                        <h4 style={{fontSize:"12px"}}>
                            {headerSubText}
                        </h4> */}
                        {props.data.logoUrl ? (<img src={props.data.logoUrl} width="100" style={{width:'75px',height:'36px',objectFit:'contain'}} />) : null}
                    </td>
                    {ReprintCode}
                </tr>
                <tr>
                <td width="35%" align="left">
                        <p>
                        <span className="w-25 text-start">TRN No:</span>
                        <span className="w-50 text-center font-sans-serif"><b> {props.data.transactionNumber} </b></span>
                        <span className="w-25 text-end">
                            :العدد
                        </span>
                        </p>
                        <p>
                        <span className="w-20 text-start">Date:</span>
                        <span className="w-60 text-center"><b> {props.data.remittanceDateDisplay} </b></span>
                        <span className="w-20 text-end">
                            :التاريخ
                        </span>
                        </p>
                    </td>
                    <td align="center" valign="top">
                    <h4>
                            {headerText}
                        </h4>
                        <h4 style={{fontSize:"12px"}}>
                            {headerSubText}
                        </h4>
                    </td>
                    <td width="35%" align="right" valign="bottom">
                        <p>
                            <span className="w-25 text-start">Branch:</span>
                            <span className="w-50 text-center"><b> {props.data.branchName} </b></span>
                            <span className="w-25 text-end" style={{fontSize:"8.4px"}}>
                                :الفرع
                            </span>
                        </p>
                        {props.data.branchAddress ? (
                            <p>
                                <span className="w-25 text-start">Address:</span>
                                <span className="w-50 text-center"><b>{props.data.branchAddress} </b></span>
                                <span className="w-25 text-end" style={{fontSize:"8.4px"}}>
                                    :العنوان
                                </span>
                            </p>
                        ) : null}
                        <p>
                        <span className="w-25 text-start">Phone:</span>
                            <span className="w-50 text-center"><b> {props.data.phone} </b></span>
                            <span className="w-25 text-end" style={{fontSize:"8.4px"}}>
                                :هاتف
                            </span>
                        </p>
                    </td>
                </tr>
            </tbody>
        </table>
    );
}

function Signature(props) {
    return (
        <table width="100%" height="40">
            <tbody>
                <tr>
                    <td width="55%" style={{verticalAlign: "middle"}}>
                        <br />
                        <span className="w-50">Customer Signature / توقيع الزبون
                            </span>
                    </td>
                    <td width="1%" style={{verticalAlign: "middle"}}>

                    </td>
                    <td width="44%" className="text-end" style={{verticalAlign: "middle"}}>
                <span style={{marginTop: "5px"}}>{props.data.deskName} Cashier Signature 
                    &nbsp;&nbsp;
                    {/* <span className="text-start">Cashier:</span> */}
                            <span className="text-center"><b style={{margin:" 0 8px"}}> {props.data.userName} </b></span>
                            <span className="text-end" style={{fontSize:"8.4px"}}>
                                :أمين الصندوق
                            </span>
                            &nbsp;&nbsp;
                             <span style={{fontSize:"8.4px"}}>توقيع</span></span>
                                              
                    </td>
                </tr>
            </tbody>
        </table>
    );
}

function SenderInfo(props) {
    let senderEnTitle = "Customer Information";
    let senderArTitle = "معلومات الزبون";

    if(props.data.isSendReceipt || props.data.isPayReceipt || props.data.isImportRemittanceReceipt){
        senderEnTitle = "Sender Information";
        senderArTitle = "معلومات المرسل";
    }

    let senderName = props.data.senderName;
    let senderNamrAr = props.data.senderNameAr;
    if (senderName == undefined || senderName == null){
        senderName = "";
    }
    if (senderNamrAr == undefined || senderNamrAr == null){
        senderNamrAr = "";
    }
    if (senderName != "" && senderNamrAr != ""){
        senderName += " / " + senderNamrAr;
    }else{
        senderName += senderNamrAr;
    }
    let senderMotherName = props.data.senderMotherName;
    if (props.data.senderMotherNameAr != undefined && props.data.senderMotherNameAr != ""){
        senderMotherName += " / " + props.data.senderMotherNameAr;
    }
    let senderAddress = props.data.senderAddress;
    if (senderAddress == undefined || senderAddress == null){
        senderAddress = "";
    }
    if (props.data.senderCity != undefined){
        if (senderAddress == ""){
            senderAddress = props.data.senderCity;
        }else{
            senderAddress += ", " + props.data.senderCity;
        }
    }
    if (props.data.senderCountry != undefined){
        senderAddress += ", " + props.data.senderCountry;
    }
    return (
        <tr>
            <td width="100%" className="p-0">
                <h3><span className="w-50">{senderEnTitle}</span>
                    <span className="w-50 text-right">
                    {senderArTitle}
                    </span>
                </h3>
                <p style={{paddingBottom: '2px'}}>
                    <span className="w-15 text-start">Name:</span>
                    <span className="w-70 text-center"><b className="lineclamp-2"> {senderName}</b></span>
                        <span className="w-15 text-end" style={{fontSize:"8.4px"}}>:الاسم</span>
                </p>
                <p>
                    <span className="w-15 text-start">Address:</span>
                    <span className="w-70 text-center"><b className="lineclamp-2"> {senderAddress} </b></span>
                        <span className="w-15 text-end" style={{fontSize:"8.4px"}}>
                            :العنوان
                        </span>
                </p>
                <p>
                    <span className="w-50">
                        <span className="w-25 text-start">Phone:</span>
                        <span className="w-50 text-center"><b> {props.data.senderPhone} </b></span>
                            <span className="w-25 text-end">
                                :هاتف
                            </span>
                    </span>
                    <span className="w-50">
                        <span className="w-25 text-start">Email:</span>
                        <span className="w-50 text-center"><b> {props.data.senderEmail} </b></span>
                            <span className="w-25 text-end">
                            :إيميل
                            </span>
                    </span>
                </p>
                <p>
                    <span className="w-50">
                        <span className="w-25 text-start">Nationality:</span>
                        <span className="w-50 text-center"><b> {props.data.nationality} </b></span>
                        <span className="w-25 text-end">
                            :الجنسية
                        </span>
                    </span>
                       
                    <span className="w-50">
                        <span className="w-25 text-start">ID No:</span>
                        <span className="w-50 text-center"><b> {props.data.senderDocId} </b></span>
                            <span className="w-25 text-end">
                                :رقمها
                            </span>
                    </span>
                </p>
                <p> 
                        <span className="w-25 text-start">ID Type:</span>
                        <span className="w-50 text-center"><b> {props.data.idType} </b></span>
                            <span className="w-25 text-end">
                                :الوثيقة
                            </span>
                </p>
                <p>
                        <span className="w-25 text-start">Issued by:</span>
                        <span className="w-50 text-center"><b> {props.data.issuePlace} </b></span>
                            <span className="w-25 text-end">
                                :صادرة عن
                            </span>
                </p>
                <p>
                    <span className="w-50">
                        <span className="w-25 text-start">Issue date:</span>
                        <span className="w-50 text-center"><b> {TrimTime(props.data.senderDocIssueDate)} </b></span>
                            <span className="w-25 text-end">
                                :ت.إصدار
                            </span>
                    </span>
                    <span className="w-50">
                        <span className="w-25 text-start">Expiry:</span>
                        <span className="w-50 text-center"><b> {TrimTime(props.data.senderDocExpiryDate)} </b></span>
                            <span className="w-25 text-end">
                                :الصلاحية
                            </span>
                    </span>
                </p>
                <p>
                    <span className="w-50">
                        <span className="w-25 text-start">Job:</span>
                        <span className="w-50 text-center"><b> {props.data.job} </b></span>
                            <span className="w-25 text-end">
                                :المهنة
                            </span>
                    </span>
                    <span className="w-50">
                        <span className="w-25 text-start">Employer:</span>
                        <span className="w-50 text-center"><b> {props.data.employer} </b></span>
                            <span className="w-25 text-end">
                            :جهة العمل
                            </span>
                    </span>
                </p>
            </td>
        </tr>
    );
}
function SenderInfoBasic(props) {
    let senderName = props.data.senderName;
    let senderNamrAr = props.data.senderNameAr;
    if (senderName == undefined || senderName == null){
        senderName = "";
    }
    if (senderNamrAr == undefined || senderNamrAr == null){
        senderNamrAr = "";
    }
    if (senderName != "" && senderNamrAr != ""){
        senderName += " / " + senderNamrAr;
    }else{
        senderName += senderNamrAr;
    }
    let senderAddress = props.data.senderAddress;
    if (senderAddress == undefined || senderAddress == null){
        senderAddress = "";
    }
    if (props.data.senderCity != undefined){
        if (senderAddress == ""){
            senderAddress = props.data.senderCity;
        }else{
            senderAddress += ", " + props.data.senderCity;
        }
    }
    if (props.data.senderCountry != undefined){
        senderAddress += ", " + props.data.senderCountry;
    }
    return (
        <tr>
            <td width="100%" className="p-0">
                <h3><span className="w-50">Sender Information</span>
                    <span className="w-50 text-right">
                         معلومات المرسل  
                    </span>
                </h3>
                <p style={{paddingBottom: '2px'}}>
                    <span className="w-15 text-start">Name:</span>
                    <span className="w-70 text-center"><b className="lineclamp-2"> {senderName}</b></span>
                        <span className="w-15 text-end" style={{fontSize:"8.4px"}}>
                            :الاسم
                        </span>
                </p>
                <p>
                    <span className="w-15 text-start">Address:</span>
                    <span className="w-70 text-center"><b className="lineclamp-2"> {senderAddress} </b></span>
                        <span className="w-15 text-end" style={{fontSize:"8.4px"}}>
                            :العنوان
                        </span>
                </p>
                <p>
                    <span className="w-50">
                        <span className="w-25 text-start">Phone:</span>
                        <span className="w-50 text-center"><b> {props.data.senderPhone} </b></span>
                            <span className="w-25 text-end">
                                :هاتف
                            </span>
                    </span>
                    <span className="w-50">
                        <span className="w-25 text-start">Email:</span>
                        <span className="w-50 text-center"><b> {props.data.senderEmail} </b></span>
                            <span className="w-25 text-end">
                            :إيميل
                            </span>
                    </span>
                </p>
            </td>
        </tr>
    );
}
function BenificiaryInfo(props) {
    let beneficiaryName = props.data.beneficiaryName;
    let beneficiaryNameAr = props.data.beneficiaryNameAr;
    if (beneficiaryName == undefined || beneficiaryName == null){
        beneficiaryName = "";
    }
    if (beneficiaryNameAr == undefined || beneficiaryNameAr == null){
        beneficiaryNameAr = "";
    }
    if (beneficiaryName != "" && beneficiaryNameAr != ""){
        beneficiaryName += " / " + beneficiaryNameAr;
    }else{
        beneficiaryName += beneficiaryNameAr;
    }
    let beneficiaryAddress = props.data.beneficiaryAddress;
    if (beneficiaryAddress == undefined || beneficiaryAddress == null){
        beneficiaryAddress = "";
    }
    if (props.data.beneficiaryCity != undefined){
        if (beneficiaryAddress == ""){
            beneficiaryAddress = props.data.beneficiaryCity;
        }else{
            beneficiaryAddress += ", " + props.data.beneficiaryCity;
        }
    }
    if (props.data.beneficiaryCountry != undefined){
        beneficiaryAddress += ", " + props.data.beneficiaryCountry;
    }
    return (
        <tr>
            <td width="100%" className="p-0">
                <h3>
                    <span className="w-50">Benificiary Information</span>
                    <span className="w-50 text-right">
                          معلومات المستلم
                    </span>
                </h3>
                <p style={{paddingBottom: '2px'}}>
                    <span className="w-15 text-start">Name:</span>
                        <span className="w-70 text-center"><b> {beneficiaryName} </b></span>
                            <span className="w-15 text-end" style={{fontSize:"8.4px"}}>
                                :الاسم
                            </span>
                </p>
                <p>
                    <span className="w-15 text-start">Address:</span>
                        <span className="w-70 text-center"><b className="lineclamp-2"> {beneficiaryAddress} </b></span>
                            <span className="w-15 text-end" style={{fontSize:"8.4px"}}>
                                :العنوان
                            </span>
                </p>
                <p>
                    <span className="w-50">
                        <span className="w-25 text-start">Phone:</span>
                        <span className="w-50 text-center"><b> {props.data.beneficiaryPhone} </b></span>
                            <span className="w-25 text-end">
                                :هاتف
                            </span>
                    </span>
                    <span className="w-50">
                        <span className="w-25 text-start">Email:</span>
                        <span className="w-50 text-center"><b> {props.data.beneficiaryEmail} </b></span>
                            <span className="w-25 text-end">
                                :إيميل
                            </span>
                    </span>
                </p>
            </td>
        </tr>
    );
}
function BenificiaryFullInfo(props) {
    let beneficiaryName = props.data.beneficiaryName;
    let beneficiaryNameAr = props.data.beneficiaryNameAr;
    if (beneficiaryName == undefined || beneficiaryName == null){
        beneficiaryName = "";
    }
    if (beneficiaryNameAr == undefined || beneficiaryNameAr == null){
        beneficiaryNameAr = "";
    }
    if (beneficiaryName != "" && beneficiaryNameAr != ""){
        beneficiaryName += " / " + beneficiaryNameAr;
    }else{
        beneficiaryName += beneficiaryNameAr;
    }
    let beneficiaryMotherName = props.data.beneficiaryMotherName;
    if (props.data.beneficiaryMotherNameAr != undefined && props.data.beneficiaryMotherNameAr != null){
        beneficiaryMotherName += " / " + props.data.beneficiaryMotherNameAr;
    }
    let beneficiaryAddress = props.data.beneficiaryAddress;
    if (beneficiaryAddress == undefined || beneficiaryAddress == null){
        beneficiaryAddress = "";
    }
    if (props.data.beneficiaryCity != undefined){
        if (beneficiaryAddress == ""){
            beneficiaryAddress = props.data.beneficiaryCity;
        }else{
            beneficiaryAddress += ", " + props.data.beneficiaryCity;
        }
    }
    if (props.data.beneficiaryCountry != undefined){
        beneficiaryAddress += ", " + props.data.beneficiaryCountry;
    }
    return (
        <tr>
            <td width="100%" className="p-0">
                <h3><span className="w-50">Benificiary Information</span>
                    <span className="w-50 text-right">
                    معلومات المستلم  
                    </span>
                </h3>
                <p style={{paddingBottom: '2px'}}>
                    <span className="w-15 text-start">Name:</span>
                    <span className="w-70 text-center"><b> {beneficiaryName}</b></span>
                        <span className="w-15 text-end" style={{fontSize:"8.4px"}}>
                            :الاسم
                        </span>
                </p>
                <p>
                    <span className="w-15 text-start">Address:</span>
                    <span className="w-70 text-center"><b className="lineclamp-2"> {beneficiaryAddress} </b></span>
                        <span className="w-15 text-end">
                            :العنوان
                        </span>
                </p>
                <p>
                    <span className="w-50">
                        <span className="w-25 text-start">Phone:</span>
                        <span className="w-50 text-center"><b> {props.data.beneficiaryPhone} </b></span>
                            <span className="w-25 text-end">
                                :هاتف
                            </span>
                    </span>
                    <span className="w-50">
                        <span className="w-25 text-start">Email:</span>
                        <span className="w-50 text-center"><b> {props.data.beneficiaryEmail} </b></span>
                            <span className="w-25 text-end">
                            :إيميل
                            </span>
                    </span>
                </p>
                <p>
                    <span className="w-50">
                        <span className="w-25 text-start">Nationality:</span>
                        <span className="w-50 text-center"><b> {props.data.nationalityBenificiary} </b></span>
                        <span className="w-25 text-end">
                            :الجنسية
                        </span>
                    </span>
                    <span className="w-50">
                        <span className="w-25 text-start">ID No:</span>
                        <span className="w-50 text-center"><b> {props.data.docId} </b></span>
                            <span className="w-25 text-end">
                                :رقمها
                            </span>
                    </span>
                </p>
                <p>
                    <span className="w-50">
                        <span className="w-25 text-start">ID Type:</span>
                        <span className="w-50 text-center"><b> {props.data.idType} </b></span>
                            <span className="w-25 text-end">
                                :الوثيقة
                            </span>
                    </span>

                    <span className="w-50">
                        <span className="w-25 text-start">Issued by:</span>
                        <span className="w-50 text-center"><b> {props.data.issuePlace} </b></span>
                            <span className="w-25 text-end">
                                :صادرة عن
                            </span>
                    </span>
                </p>
                <p>                    
                    <span className="w-50">
                        <span className="w-25 text-start">Issue date:</span>
                        <span className="w-50 text-center"><b> {TrimTime(props.data.docIssueDate)} </b></span>
                            <span className="w-25 text-end">
                                :ت.إصدار
                            </span>
                    </span>
                    <span className="w-50">
                        <span className="w-25 text-start">Expiry:</span>
                        <span className="w-50 text-center"><b> {TrimTime(props.data.docExpiryDate)} </b></span>
                            <span className="w-25 text-end">
                                :الصلاحية
                            </span>
                    </span>
                </p>
                <p>
                    <span className="w-50">
                        <span className="w-25 text-start">Job:</span>
                        <span className="w-50 text-center"><b> {props.data.beneficiaryJob} </b></span>
                            <span className="w-25 text-end">
                                :المهنة
                            </span>
                    </span>
                    <span className="w-50">
                        <span className="w-25 text-start">Employer:</span>
                        <span className="w-50 text-center"><b> {props.data.employer} </b></span>
                            <span className="w-25 text-end" style={{fontSize:"8.4px"}}>
                            :جهة العمل
                            </span>
                    </span>
                </p>
            </td>
        </tr>
    );
}
function OtherInfo(props) {
    return (
        <tr>
            <td width="100%" className="p-0">
                <h3>
                    <span className="w-50">Remmitance Information</span>
                    <span className="w-50 text-right">
                          معلومات الحوالة
                    </span>
                </h3>
                <p>
                    <span className="w-25 text-start">Source of Fund:</span>
                    <span className="w-50 text-center"><b style={{fontSize:"9px"}}> {props.data.fundSource} </b></span>
                    <span className="w-25 text-end" style={{fontSize:"8.4px"}}>
                        :مصدر المال
                    </span>
                </p>
                <p>
                    <span className="w-25 text-start">Sender/Benif. Relation:</span>
                    <span className="w-50 text-center"><b> {props.data.relation} </b></span>
                    <span className="w-25 text-end" style={{fontSize:"8.4px"}}>
                        :علاقة الطرفين
                    </span>
                </p>
                <p>
                    <span className="w-25 text-start">Reason of Remittenace:</span>
                    <span className="w-50 text-center"><b> {props.data.remittancePurpose} </b></span>
                    <span className="w-25 text-end" style={{fontSize:"8.4px"}}>
                        :الغرض من التحويل
                    </span>
                </p>
            </td>
        </tr>
    );
}

function FinancialInfo(props) {
    let localCurrency = props.data.localCurrency;
    let remCurrency = props.data.remittanceCurrency;
    let payCurrency = props.data.paymentCurrency;
    var exchangeRate = remCurrency + "/" + payCurrency;
    var feeExchangeRate = localCurrency + "/" + remCurrency;
    if (remCurrency != payCurrency)
    {
        if (payCurrency == localCurrency)
        {
            exchangeRate = payCurrency + "/" + remCurrency;
        }
    }
    let QRCode = <tr></tr>;
    if (props.data.isSendReceipt){
        QRCode = <tr>
            <td width="100%" colspan="4" style={{border:"4px double #dfdfdf", padding:"1px 8px"}}>
                <table  width="100%">
<tr>
<td colSpan="1" style={{padding:"8px 0 0 0"}}><GenerateQRCode data={props.data.qrCode} size={70}/>
</td>
        <td colSpan="3" style={{paddingTop:"8px"}}>
            <p>
            <span className="w-25 text-start">Remit No:</span>
                        <span className="w-50 text-center font-sans-serif"><b style={{fontSize:"11.5px"}}> {props.data.remittanceNumber} </b></span>
                        <span className="w-25 text-end" style={{fontSize:"8.4px"}}>
                            :رقم الحوالة
                        </span>

            </p>
            <p style={{paddingTop:"8px"}}>
            <span className="w-25 text-start">Code:</span>
                        <span className="w-50 text-center"><b> {props.data.password} </b></span>
                        <span className="w-25 text-end" style={{fontSize:"8.4px"}}>
                            :الرمز
                        </span>
            </p>
            <p style={{paddingTop:"8px"}}>
            <span className="w-25 text-start">Destination:</span>
                        <span className="w-50 text-center font-sans-serif"><b style={{fontSize:"11.5px"}}> {props.data.destination} </b></span>
                            <span className="w-25 text-end" style={{fontSize:"8.4px"}}>
                                :الوجهة
                            </span>
            </p>
        </td>
</tr>
<tr>
    <td colspan="4" width="100%">
    <p style={{fontSize:"7.5px"}}>
<span className="text-end">امسح الرمز لمتابعة الحوالة</span><br/>
            <span className="text-start w-50" style={{paddingLeft:"0px", fontSize:"8.4px"}}>Scan to view status</span>                            
            <span className="text-end w-50" style={{paddingRight:"0px", fontSize:"8.4px"}}>Share with Beneficiary</span>                            
            </p>
    </td>
</tr>
                </table>
            </td>
          
        </tr>;
    }

    let AdjustedRemitAmount = <tr></tr>;
    let AdjustedCredit = <tr></tr>;
    let PayBackAmount = <tr></tr>;
    let FeeInRemitCurrency = <tr></tr>;
    let FeeExchangeRate = <tr></tr>;
    let RemitFees = <tr></tr>;
    let AdminFees = <tr></tr>;
    let VIPFees = <tr></tr>;
    let VATFees = <tr></tr>;
    let FeeSubTotal = <tr></tr>;
    let Rounding = <tr></tr>;
    let ToBeCollected = "";
    let DeductedFromAmount = "";
    let RemittanceAmount = <tr></tr>;
    let RemittanceAmountInWords = <tr></tr>;
    let ExchangeRates = <tr></tr>;
    let PayoutAmount = <tr></tr>;
    let TotalFees = <tr></tr>;
    let TotalFeesInWords = <tr></tr>;
    let TotalFeesInGrand = <tr></tr>;
    let GrandTotal = <tr></tr>;
    let GrandTotalInWords = <tr></tr>;
    let grandTotal = props.data.grandTotal;    
    if (grandTotal == undefined || grandTotal == null){
        grandTotal = "";
    }    
    if (props.data.roundingFC != 0){
        AdjustedRemitAmount = 
        <tr>
        <td align="left">Adjusted Remittance Amount:
        </td>
        <td align="left">{props.data.remittanceCurrency}</td>
        <td align="right" style={{fontSize:"9px"}}>{props.data.roundingFC}</td>
        <td align="right">
            :مبلغ الحوالة مع التقريب
        </td>
    </tr>;
        AdjustedCredit = 
        <tr>
        <td align="left">Adjusted Credit:
        </td>
        <td align="left">{props.data.localCurrency}</td>
        <td align="right" style={{fontSize:"9px"}}>({props.data.roundingFCEquivalent})</td>
        <td align="right">
            :الرصيد المتبقي
        </td>
    </tr>;
    ToBeCollected = props.data.toBeCollectedFC;
    }
    if (props.data.roundingBase != 0){
        AdjustedRemitAmount = 
        <tr>
        <td align="left">Adjusted Remittance Amount:
        </td>
        <td align="left">{props.data.remittanceCurrency}</td>
        <td align="right" style={{fontSize:"9px"}}>{props.data.roundingBase}</td>
        <td align="right">
            :مبلغ الحوالة مع التقريب
        </td>
    </tr>;
        AdjustedCredit = 
        <tr>
        <td align="left">Adjusted Credit:
        </td>
        <td align="left">{props.data.localCurrency}</td>
        <td align="right" style={{fontSize:"9px"}}>({props.data.roundingBase})</td>
        <td align="right">
            :الرصيد المتبقي
        </td>
    </tr>;
    ToBeCollected = props.data.toBeCollectedBase;
    }
    if (props.data.feeSubTotal != 0){
        FeeSubTotal = 
        <tr>
        <td align="left">Fee Sub Total:
        </td>
        <td align="left">{props.data.localCurrency}</td>
        <td align="right" style={{fontSize:"9px"}}> {props.data.feeSubTotal}</td>
        <td align="right">
            :Fee Sub Total
        </td>
    </tr>;
    }
    if (props.data.amountToBePaidRounding != 0){
        PayBackAmount = 
        <tr>
        <td align="left">Paid back to Customer:
        </td>
        <td align="left">{props.data.localCurrency}</td>
        <td align="right" style={{fontSize:"9px"}}> {props.data.amountToBePaidRounding}</td>
        <td align="right">
            :مدفوع للزبون
        </td>
    </tr>;
    }
    if (props.data.feeAmountRemittanceCurrency != 0){
        FeeInRemitCurrency = 
        <tr>
        <td align="left">Fee in Remittance Currency:
        </td>
        <td align="left">{props.data.remittanceCurrency}</td>
        <td align="right" style={{fontSize:"9px"}}> {props.data.feeAmountRemittanceCurrency}</td>
        <td align="right">
            :الرسوم بعملة الحوالة
        </td>
    </tr>;
        FeeExchangeRate = 
        <tr>
        <td align="left">Exchange Rate:</td>
        <td align="left">{feeExchangeRate}</td>
        <td align="right" style={{fontSize:"9px"}}>{props.data.feeExchangeRate}</td>
        <td align="right">
            :سعر الصرف
        </td>
    </tr>;
    }
    if (props.data.remittanceFee != 0){
        RemitFees = 
        <tr>
        <td align="left">Remittance Fee:
        </td>
        <td align="left">{props.data.localCurrency}</td>
        <td align="right" style={{fontSize:"9px"}}>{props.data.remittanceFee}</td>
        <td align="right">
            :عمولة الحوالة
        </td>
    </tr>;
    }
    if (props.data.adminFee != 0){
            AdminFees = 
            <tr>
            <td align="left">Admin Fee:
            </td>
            <td align="left">{props.data.localCurrency}</td>
            <td align="right" style={{fontSize:"9px"}}>{props.data.adminFee}</td>
            <td align="right">
                :رسوم الخدمة
            </td>
        </tr>;
    }
    if (props.data.vipFee != 0){
        VIPFees = 
        <tr>
        <td align="left">VIP Fee:
        </td>
        <td align="left">{props.data.localCurrency}</td>
        <td align="right" style={{fontSize:"9px"}}>{props.data.vipFee}</td>
        <td align="right">
            :الخدمة المميزة
        </td>
    </tr>;
    }
    if (props.data.vatAmount != 0){
        VATFees = 
        <tr>
        <td align="left">VAT ({props.data.vatPercentage}%):</td>
        <td align="left">{props.data.localCurrency}</td>
        <td align="right" style={{fontSize:"9px"}}>{props.data.vatAmount}</td>
        <td align="right">
            :القيمة المضافة 
        </td>
    </tr>;
    }
    if (props.data.rounding != 0){
        Rounding = 
        <tr>
        <td align="left">Rounding:
        </td>
        <td align="left">{props.data.localCurrency}</td>
        <td align="right" style={{fontSize:"9px"}}>{props.data.rounding}</td>
        <td align="right">
            :التقريب
        </td>
    </tr>;
    }
    if (props.data.feeAmountIncluded != 0){
        DeductedFromAmount = "(Deducted from Amount)";
    }
    if (ToBeCollected == ""){
        ToBeCollected = props.data.amount;
    }
    RemittanceAmount = 
        <tr>
        <td align="left" className="valign-middle"><p>Remittance Amount:</p></td>
        <td align="left" className="valign-middle">{props.data.remittanceCurrency}</td>
        <td align="right" style={{fontSize:"11px"}} className="valign-bottom"><b>{ToBeCollected}</b></td>
        <td align="right" className="valign-bottom">:مبلغ الحوالة</td>
        </tr>;
    RemittanceAmountInWords = 
        <tr>
        <td colSpan="4" align="right" style={{fontSize:"11px"}}><b className="lineclamp-2">{props.data.amountInWordsEn}</b></td>
        </tr>;
    ExchangeRates = 
        <tr>
        <td align="left">Exchange Rate:</td>
        <td align="left">{exchangeRate}</td>
        <td align="right" style={{fontSize:"9px"}}>{props.data.exchangeRate}</td>
        <td align="right">:سعر الصرف</td>
        </tr>;
    PayoutAmount = 
        <tr>
        <td align="left">Payout Amount:</td>
        <td align="left">{props.data.paymentCurrency}</td>
        <td align="right" style={{fontSize:"9px"}}>{props.data.amountToBePaid}</td>
        <td align="right">:مبلغ التسليم</td>
        </tr>;
    TotalFees = 
        <tr>
        <td align="left">Total Fee:{DeductedFromAmount}</td>
        <td align="left">{props.data.localCurrency}</td>
        <td align="right" style={{fontSize:"9px"}}><b>{props.data.feeEquivalent}</b></td>
        <td align="right">:إجمالي الرسوم</td>
        </tr>;
    TotalFeesInWords = 
        <tr><td colSpan="3" align="right"><b>{props.data.feeAmountInWordsEn}</b></td>
        <td></td>
        </tr>;
    if (grandTotal != ""){
        RemittanceAmountInWords = <tr></tr>;
        TotalFeesInGrand = TotalFees;
        TotalFees = <tr></tr>;
        TotalFeesInWords = <tr></tr>;
        GrandTotal = 
            <tr>
            <td align="left">Grand-Total:</td>
            <td align="left">{props.data.localCurrency}</td>
            <td align="right" style={{fontSize:"9px", borderTop:"1px solid rgb(223, 223, 223)"}}><b>{props.data.grandTotal}</b></td>
            <td align="right">:المجموع</td>
            </tr>;
        GrandTotalInWords = 
        <tr><td colSpan="3" align="right"><b>{props.data.grandTotalInWordsEn}</b></td>
        <td></td>
        </tr>;
    }
return (
        <table width="100%" cellPadding="0" cellSpacing="0">
            <tbody>
                <tr>
                    <td width="100%" className="p-0">
                        <h3>
                            <span className="w-50">Financial Info</span>
                            <span className="w-50 text-right">
                                   المعلومات المالية
                            </span>
                        </h3>
                    </td>
                </tr>
                <tr>
                    <td width="100%" className="p-0">
                        <table width="100%" cellPadding="0" cellSpacing="0" className="financial-info">
                            <tbody>
                                {RemittanceAmount}
                                {RemittanceAmountInWords}
                                {TotalFeesInGrand}
                                {GrandTotal}
                                {GrandTotalInWords}
                                {/* {AdjustedRemitAmount}                               */}
                                {ExchangeRates}
                                {PayoutAmount}
                                {/* {RemitFees}                              
                                {AdminFees}
                                {VIPFees}                              
                                {VATFees}                              
                                {FeeSubTotal}
                                {AdjustedCredit}                              
                                {Rounding}  */}                             
                                {TotalFees}
                                {TotalFeesInWords}
                                <tr><td colSpan="4" width="100%" height="1px" style={{ backgroundColor: "#c3c1c1", padding: 0 }}></td></tr>
                                {FeeInRemitCurrency}
                                {FeeExchangeRate}
                                {PayBackAmount}
                                {props.data.logoUrl ? (<tr><td align="left">Payment Method:</td><td align="left">{props.data.payMethod}</td><td colSpan={2} align="left" style={{fontSize:"9px"}}>Express Remittance</td></tr>) : null}
                                {QRCode}
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
    );
}

function PayFinancialInfo(props) {
    let localCurrency = props.data.localCurrency;
    let remCurrency = props.data.remittanceCurrency;
    let payCurrency = props.data.paymentCurrency;
    var exchangeRate = remCurrency + "/" + payCurrency;
    if (remCurrency != payCurrency)
    {
        if (payCurrency == localCurrency)
        {
            exchangeRate = remCurrency + "/" + payCurrency;
        }
    }
    let QRCode = <tr></tr>;
    if (props.data.isPayReceipt){
        QRCode = <tr>
            <td width="100%" colspan="4">
                <table  width="100%">
<tr>
        <td colSpan="4" style={{paddingTop:"8px"}}>
            <p>
            <span className="w-25 text-start">Remittance No:</span>
                        <span className="w-50 text-center font-sans-serif"><b style={{fontSize:"11.5px"}}> {props.data.remittanceNumber} </b></span>
                        <span className="w-25 text-end">
                            :رقم الحوالة
                        </span>

            </p>
            <p style={{paddingTop:"2px"}}>
            <span className="w-25 text-start">Remittance Date:</span>
                        <span className="w-50 text-center"><b> {props.data.sourceRemittanceDate} </b></span>
                        <span className="w-25 text-end">
                            :تاريخ الحوالة
                        </span>
            </p>
            <p style={{paddingTop:"2px"}}>
            <span className="w-25 text-start">Destination:</span>
                        <span className="w-50 text-center font-sans-serif"><b style={{fontSize:"11.5px"}}> {props.data.destination} </b></span>
                            <span className="w-25 text-end">
                                :الوجهة
                            </span>
            </p>
            <p style={{paddingTop:"2px"}}>
            <span className="w-25 text-start">Source:</span>
                        <span className="w-50 text-center"><b style={{fontSize:"11px"}}> {props.data.source} </b></span>
                            <span className="w-25 text-end">
                                :المصدر
                            </span>
            </p>
        </td>
</tr>
                </table>
            </td>
          
        </tr>;
    }

    let PayRounding = <tr></tr>; 
    let PayRoundingWords = <tr></tr>; 
    let AdminFees = <tr></tr>;
    let VIPFees = <tr></tr>;
    let VATFees = <tr></tr>;
    let Rounding = <tr></tr>;
    if (props.data.adminFee != 0){
            AdminFees = 
            <tr>
            <td width="25%" align="left">Admin Fee:
            </td>
            <td width="15%" align="left">{props.data.localCurrency}</td>
            <td width="35%" align="right" style={{fontSize:"9px"}}>{props.data.adminFee}</td>
            <td width="25%" align="right">
                :رسوم الخدمة
            </td>
        </tr>;
    }
    if (props.data.vipFee != 0){
        VIPFees = 
            <tr>
            <td width="25%" align="left">VIP Fee:
            </td>
            <td width="15%" align="left">{props.data.localCurrency}</td>
            <td width="35%" align="right" style={{fontSize:"9px"}}>{props.data.vipFee}</td>
            <td width="25%" align="right">
                :VIP Fee
            </td>
        </tr>;
    }
    if (props.data.vatAmount != 0){
        VATFees = 
            <tr>
            <td width="25%" align="left">VAT ({props.data.vatPercentage}%):</td>
            <td width="15%" align="left">{props.data.localCurrency}</td>
            <td width="35%" align="right" style={{fontSize:"9px"}}>{props.data.vatAmount}</td>
            <td width="25%" align="right">
                :القيمة المضافة 
            </td>
        </tr>;
    }
    if (props.data.rounding != 0){
        Rounding = 
            <tr>
            <td width="25%" align="left">Rounding:
            </td>
            <td width="15%" align="left">{props.data.localCurrency}</td>
            <td width="35%" align="right" style={{fontSize:"9px"}}>{props.data.rounding}</td>
            <td width="25%" align="right">
                :التقريب
            </td>
        </tr>;
    }
    let roundingFees = props.data.amountToBePaidRounding;
    roundingFees = roundingFees.replaceAll(',', '');
    var amount = parseFloat(roundingFees);
    if (amount != 0){
        PayRounding = 
            <tr>
                <td width="25%" align="left" className="valign-middle">
                    <p>Remitted Amount:</p>
                </td>
                <td width="15%" align="left" className="valign-middle">{props.data.localCurrency}</td>
                <td width="35%" align="right" style={{fontSize:"9px"}} className="valign-bottom"><b>{props.data.amountToBePaidRounding}</b></td>
                <td width="25%" align="right" className="valign-bottom">
                    :مبلغ الحوالة
                </td>
            </tr>;
        PayRoundingWords =  
          <tr>
                <td colSpan="3" align="right"><b>
                {props.data.payRoundingAmountInWordsEn}
                    </b></td>
                    <td></td>
            </tr>;
    }


    return (
        <table width="100%" cellPadding="0" cellSpacing="0">
            <tbody>
                <tr>
                    <td width="100%" className="p-0">
                        <h3>
                            <span className="w-50">Financial Info</span>
                            <span className="w-50 text-right">
                                   المعلومات المالية
                            </span>
                        </h3>
                    </td>
                </tr>
                <tr>
                    <td width="100%" className="p-0">
                        <table width="100%" cellPadding="0" cellSpacing="0" className="financial-info">
                            <tbody>
                                <tr>
                                    <td width="25%" align="left" className="valign-middle">
                                        <p>Remitted Amount:</p>
                                    </td>
                                    <td width="15%" align="left" className="valign-middle">{props.data.paymentCurrency}</td>
                                    <td width="35%" align="right" style={{fontSize:"9px"}} className="valign-bottom"><b>{props.data.amountToBePaid}</b></td>
                                    <td width="25%" align="right" className="valign-bottom">
                                        :مبلغ الحوالة
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="4" align="right" style={{fontSize:"11px"}}><b className="lineclamp-2">
                                    {props.data.amountInWordsEn}
                                        </b></td>
                                </tr>
                                {/* {PayRounding}                              
                                {PayRoundingWords}                               */}
                                <tr>
                                    <td width="25%" align="left">Exchange Rate:</td>
                                    <td width="15%" align="left">{exchangeRate}</td>
                                    <td width="35%" align="right" style={{fontSize:"9px"}}>{props.data.exchangeRate}</td>
                                    <td width="25%" align="right">
                                        :سعر الصرف
                                    </td>
                                </tr>
                                {/* {AdminFees}                               */}
                                {VIPFees}                              
                                {VATFees}                              
                                {/* {Rounding}                               */}
                                <tr>
                                    <td width="25%" align="left">Total Fee:
                                    </td>
                                    <td width="15%" align="left">{props.data.localCurrency}</td>
                                    <td width="35%" align="right" style={{fontSize:"9px"}}><b>{props.data.feeEquivalent}</b></td>
                                    <td width="25%" align="right">
                                        :إجمالي الرسوم
                                    </td>
                                </tr>
                                <tr><td colSpan="3" align="right"><b>
                                    {props.data.feeAmountInWordsEn}
                                        </b></td>
                                        <td></td>
                                        </tr>
                                <tr><td colSpan="4" width="100%" height="1px" style={{ backgroundColor: "#c3c1c1", padding: 0 }}></td></tr>
                            </tbody>
                            {props.data.logoUrl ? (<tr><td align="left">Payment Method:</td><td align="left">{props.data.payMethod}</td><td colSpan={2} align="left" style={{fontSize:"9px"}}>Express Remittance</td></tr>) : null}
                            {QRCode}
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
    );
}
function ReverseFinancialInfo(props) {
    let localCurrency = props.data.localCurrency;
    let remCurrency = props.data.remittanceCurrency;
    let payCurrency = props.data.paymentCurrency;
    var exchangeRate = remCurrency + "/" + payCurrency;
    if (remCurrency != payCurrency)
    {
        if (payCurrency == localCurrency)
        {
            exchangeRate = remCurrency + "/" + payCurrency;
        }
    }
    let QRCode = <tr></tr>;
    if (props.data.isReverseReceipt){
        QRCode = <tr>
            <td width="100%" colspan="4">
                <table  width="100%">
<tr>
        <td colSpan="4" style={{paddingTop:"8px"}}>
            <p>
            <span className="w-25 text-start">Remittance No:</span>
                        <span className="w-50 text-center font-sans-serif"><b style={{fontSize:"11.5px"}}> {props.data.remittanceNumber} </b></span>
                        <span className="w-25 text-end">
                            :رقم الحوالة
                        </span>

            </p>
        </td>
</tr>
                </table>
            </td>
          
        </tr>;
    }

    return (
        <table width="100%" cellPadding="0" cellSpacing="0">
            <tbody>
                <tr>
                    <td width="100%" className="p-0">
                        <h3>
                            <span className="w-50">Financial Info</span>
                            <span className="w-50 text-right">
                                   المعلومات المالية
                            </span>
                        </h3>
                    </td>
                </tr>
                <tr>
                    <td width="100%" className="p-0">
                        <table width="100%" cellPadding="0" cellSpacing="0" className="financial-info">
                            <tbody>
                                <tr>
                                    <td width="25%" align="left" className="valign-middle">
                                        <p>Refunded Amount:</p>
                                    </td>
                                    <td width="15%" align="left" className="valign-middle">{props.data.remittanceCurrency}</td>
                                    <td width="35%" align="right" style={{fontSize:"9px"}} className="valign-bottom"><b>{props.data.amountToBePaid}</b></td>
                                    <td width="25%" align="right" className="valign-bottom">
                                        :المبلغ المردود
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="4" align="right" style={{fontSize:"11px"}}><b className="lineclamp-2">
                                    {props.data.amountInWordsEn}
                                        </b></td>
                                </tr>
                                <tr>
                                    <td width="25%" align="left">Refunded Fees:
                                    </td>
                                    <td width="15%" align="left">{props.data.localCurrency}</td>
                                    <td width="35%" align="right" style={{fontSize:"9px"}}><b>{props.data.feeEquivalent}</b></td>
                                    <td width="25%" align="right">
                                        :الرسوم المردودة
                                    </td>
                                </tr>
                                <tr><td colSpan="3" align="right"><b>
                                    {props.data.feeAmountInWordsEn}
                                        </b></td>
                                        <td></td>
                                        </tr>
                                <tr><td colSpan="4" width="100%" height="1px" style={{ backgroundColor: "#c3c1c1", padding: 0 }}></td></tr>
                            </tbody>
                            {props.data.logoUrl ? (<tr><td align="left">Payment Method:</td><td align="left">{props.data.payMethod}</td><td colSpan={2} align="left" style={{fontSize:"9px"}}>Express Remittance</td></tr>) : null}
                            {QRCode}
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
    );
}
function RefundFinancialInfo(props) {

    let QRCode = <tr></tr>;
    let RemitFees = <tr></tr>;
    let GapFees = <tr></tr>;
    let AdminFees = <tr></tr>;
    let VAT = <tr></tr>;
    let Rounding = <tr></tr>;
    let amountInWordsEn = props.data.amountInWordsEn;
    if (props.data.isModifyReceipt){
        amountInWordsEn = props.data.feeAmountInWordsEn;
        RemitFees = <tr>
        <td width="25%" align="left">Gap Fee:
        </td>
        <td width="15%" align="left">{props.data.localCurrency}</td>
        <td width="35%" align="right" style={{fontSize:"9px"}}>{props.data.remittanceFee}</td>
        <td width="25%" align="right">
            :فرق عمولة الحوالة
        </td>
    </tr>;
    }
    if (props.data.isRefundReceipt && props.data.feeDifference != '0.000'){
        amountInWordsEn = props.data.feeAmountInWordsEn;
        GapFees = <tr>
        <td width="25%" align="left">Gap Fee:
        </td>
        <td width="15%" align="left">{props.data.localCurrency}</td>
        <td width="35%" align="right" style={{fontSize:"9px"}}>{props.data.feeDifference}</td>
        <td width="25%" align="right">
            :فرق عمولة الحوالة
        </td>
    </tr>;
    }
    if (props.data.isRefundReceipt || props.data.isModifyReceipt){
        QRCode = <tr><td colSpan="1" style={{ paddingTop: "9px"}}><GenerateQRCode data={props.data.qrCode} size={70}/></td>
        <td colSpan="3" style={{ paddingTop: "9px"}}>
        <span className="w-25 text-start">Remit No:</span>
                        <span className="w-50 text-center font-sans-serif"><b style={{fontSize:"11.5px"}}> {props.data.remittanceNumber} </b></span>
                        <span className="w-25 text-end">
                            :رقم الحوالة
                        </span>
                        <p style={{ padding: "5px 0 0"}} >
                        <span className="w-50 d-inlineblock" style={{lineHeight:"1.1"}}>When request is approved, you will receive a new remittance number/code</span>
                        <span className="w-50 d-inlineblock" dir="rtl" style={{fontSize: "8px",lineHeight:"1.3"}}>عند الموافقة على الطلب، ستصلك رسالة برقم ورمز الحوالة الجديدة</span>
                         </p>
        </td>      
        </tr>;
    }
    if (props.data.isPaymentReversalReceipt){
        amountInWordsEn = props.data.feeAmountInWordsEn;
        QRCode = <tr><td colSpan="1" style={{ paddingTop: "9px"}}><GenerateQRCode data={props.data.qrCode} size={70}/></td>
        <td colSpan="3" style={{ paddingTop: "9px"}}>
        <span className="w-25 text-start">Remit No:</span>
                        <span className="w-50 text-center font-sans-serif"><b style={{fontSize:"11.5px"}}> {props.data.remittanceNumber} </b></span>
                        <span className="w-25 text-end">
                            :رقم الحوالة
                        </span>
                        <p style={{ padding: "5px 0 0"}} >
                        <span className="w-50 d-inlineblock" style={{lineHeight:"1.1"}}>To track processing of your request, scan the QR code to check Remittance Status</span>
                        <span className="w-50 d-inlineblock" dir="rtl" style={{fontSize: "8px",lineHeight:"1.3"}}>لمتابعة نتيجة الطلب، قم بمسح رمز الاستجابة السريعة لمعرفة حالة الحوالة</span>
                         </p>
        </td>        
        </tr>;
    }
   
    if (props.data.vatAmount > 0){
        VAT =  <tr>
        <td width="25%" align="left">VAT ({props.data.vatPercentage}%):</td>
        <td width="15%" align="left">{props.data.localCurrency}</td>
        <td width="35%" align="right" style={{fontSize:"9px"}}>{props.data.vatAmount}</td>
        <td width="25%" align="right">
            :القيمة المضافة
        </td>
    </tr>
    }

    if (props.data.rounding > 0){
        Rounding =   <tr>
        <td width="25%" align="left">Rounding:
        </td>
        <td width="15%" align="left">{props.data.localCurrency}</td>
        <td width="35%" align="right" style={{fontSize:"9px"}}>{props.data.rounding}</td>
        <td width="25%" align="right">
            :التقريب
        </td>
    </tr>
    }

    return (
        <table width="100%" cellPadding="0" cellSpacing="0">
            <tbody>
                <tr>
                    <td width="100%" className="p-0">
                        <h3>
                            <span className="w-50">Financial Info</span>
                            <span className="w-50 text-right">
                                   المعلومات المالية
                            </span>
                        </h3>
                    </td>
                </tr>
                <tr>
                    <td width="100%" className="p-0">
                        <table width="100%" cellPadding="0" cellSpacing="0" className="financial-info">
                            <tbody>
                                  {RemitFees}                    
                                  {GapFees}                    
                            <tr>
                                <td width="25%" align="left">Admin Fee:
                                </td>
                                <td width="15%" align="left">{props.data.localCurrency}</td>
                                <td width="35%" align="right" style={{fontSize:"9px"}}>{props.data.adminFee}</td>
                                <td width="25%" align="right">
                                    :رسوم الخدمة
                                </td>
                                </tr> 
     
                                  {VAT}
                                  {Rounding}
                                <tr>
                                    <td width="25%" align="left">Total Fee:
                                    </td>
                                    <td width="15%" align="left">{props.data.localCurrency}</td>
                                    <td width="35%" align="right" style={{fontSize:"9px"}}><b>{props.data.feeEquivalent}</b></td>
                                    <td width="25%" align="right">
                                        :إجمالي الرسوم
                                    </td>
                                </tr>
                                <tr>
                                    <td width="35%" colSpan="3" align="right" style={{fontSize:"9px"}}><b>{amountInWordsEn}</b></td>
                                    <td width="25%" align="right"></td>
                                </tr>
                                <tr><td colSpan="4" width="100%" height="1px" style={{ backgroundColor: "#c3c1c1", padding: 0 }}></td></tr>
                                {QRCode}
                                <tr><td colSpan="2"></td>
        {/* <td colSpan="2">  <p> When request is approved, you will receive a new Remittance number / code via [SMS / Email / WhatsApp]</p>
        <p>عند الموافقة على الطلب، ستصلك رسالة برقم ورمز الحوالة الجديدة عبر [SMS/Email/WhatsApp]</p></td> */}
        </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
    );
}
export { Header, Signature, SenderInfo, SenderInfoBasic, BenificiaryInfo,BenificiaryFullInfo, OtherInfo, FinancialInfo, 
    PayFinancialInfo, RefundFinancialInfo, ReverseFinancialInfo };