import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { DecimalToFixed, FormatAmount, TrimTime } from "../../utils/helper";
import Barcode from "react-barcode";

const tdStyle = { direction: 'ltr' };

function Header(props) {

    let ReprintCode = <td width="35%" align="right" valign="top"></td>;
    
    if (props.isReprint)
    {
        ReprintCode = <td width="35%" align="left" valign="top">
                        <span className="w-20 text-start">Reprint:</span>
                        <span className="w-60 text-center">{props.data.printDate}</span>
                        <span className="w-20 text-end text-rtl">إعادة طباعة:</span>
                      </td>;
    }

    return (
    <div className="page-header" style={{textAlign: 'center'}}>
        <table width="100%" cellPadding={0} cellSpacing={0}>
            <tbody>
            <tr>
                <td width="35%" align="left">
                    <p className="mb-0">
                        <span className="w-75 d-inlineblock valign-middle">
                            <Barcode
                                    value={props.data.transactionNo}
                                    height={25}
                                    width={1}
                                    fontSize={10}
                                    margin={5}
                            />
                        </span>
                    </p>
                </td> 
                <td align="center">
                    <h3 style={{margin: '0 0 15px'}}>
                        محضر جرد الصندوق
                        <br />
                        Cash Calculation Report
                    </h3>
                </td>
                {ReprintCode}
            </tr>
            <tr>
                <td width="40%" align="left">        
                    <td className="w-25 text-start">Report No:</td>
                    <td className="w-50 text-center">{props.data.transactionNo}</td>
                    <td className="w-25 text-end text-rtl" align="right">رقم التقرير:</td>

                    <td className="w-20 text-start">Date:</td>
                    <td className="w-60 text-center">{props.data.recordDate}</td>
                    <td className="w-20 text-end text-rtl" align="right">التاريخ:</td>  
                </td>

                <td align="center" valign="top"></td>

                <td width="40%" align="left" valign="top">
                    <td className="w-25 text-start">Branch:</td>
                    <td className="w-50 text-center">{props.data.branchName}</td>
                    <td className="w-25 text-end text-rtl" width="15%" align="right">الفرع:</td>

                    <td className="w-25 text-start">Teller:</td>
                    <td className="w-50 text-center">{props.data.userName}</td>
                    <td className="w-25 text-end text-rtl" align="right">أمين الصندوق:</td>
                </td>
            </tr>

            <tr>           
                <td width="40%" align="left"></td>
                <td align="center" valign="top"></td>

                <td width="40%" align="left" valign="top" className="pad-top">
                    <td className="w-25 text-start">Cash Box:</td>
                    <td className="w-50 text-center">{props.data.boxName}</td>
                    <td className="w-25 text-end text-rtl" align="right">الصندوق النقدي:</td> 

                    {props.data.deskNo > 0 && 
                        <>
                            <td className="w-25 text-start" align="left">Desk:</td>
                            <td className="w-50 text-center">{props.data.deskNo}</td>
                            <td className="w-25 text-end text-rtl" align="right">كاونتر:</td>
                        </>                      
                    }   
                </td>
            </tr>

            </tbody>
        </table>
    </div>
    );
}

function Signature(props) {
    return (
        <div></div>  
    );
}

function CashReconciliationListInfo(props) {
    return (
    <table width="100%" cellPadding={0} cellSpacing={0}>
        <br />
        <br />
        <thead>
            <tr>
            <td>
                {/*place holder for the fixed-position header*/}
                <div className="page-header-space" />
            </td>
            </tr>
        </thead>
        <tbody>
            <tr>
            <td width="100%" style={{padding: 0}}>
                <table width="100%" cellPadding={0} cellSpacing={0}>
                <tbody><tr><td>The cash in the box {props.data.boxName} under the custody of teller {props.data.userName} has been counted at {props.data.recordDate}.</td><td className="text-rtl">تم جرد النقد في صندوق رقم {props.data.boxName} بعهدة أمين الصندوق {props.data.userName} بتاريخ {props.data.recordDate}</td></tr>
                    <tr><td>The outcome was as follows:</td><td className="text-rtl">وكانت حصيلة الاحتساب كالتالي:</td></tr>
                    <tr><td colSpan={2} width="100%" height={10} /></tr>
                </tbody></table>
            </td>
            </tr>
            <tr>
            <td width="100%" style={{padding: 0}}>
                <table width="100%" cellPadding={0} cellSpacing={0} border={1}>
                <thead>
                    <tr>
                        <th>#</th>
                        <th><div style={{fontSize: '10px'}}>العملة</div>Currency</th>
                        <th><div style={{fontSize: '10px'}}>رصيد النظام</div>System Balance</th>
                        <th><div style={{fontSize: '10px'}}>الرصيد النقدي</div>Cash Balance</th>
                        <th><div style={{fontSize: '10px'}}>الفائض/العجز</div>Excess/Deficit</th>
                        <th><div style={{fontSize: '10px'}}>الحالة</div>Status</th>
                        <th><div style={{fontSize: '10px'}}>الملاحظات</div>Comments</th>
                    </tr>
                </thead>
                <tbody>
                {props.data.cashReconcileDetails.map(
                                        (item, index) => (
                                            <tr className="text-right pad-right" key={index}>
                                                <td className="text-center no-padding" width="25px">{index + 1}</td>
                                                <td>{item.currencyISO}</td>
                                                <td style={tdStyle}>{item.systemBalance}</td>
                                                <td style={tdStyle}>{item.actualAmount}</td>
                                                <td style={tdStyle}>{item.differenceAmount}</td>
                                                <td className="text-center">{item.status}</td>
                                                <td className="text-center">{item.comments}</td>
                                            </tr>
                                        )
                                    )}
                </tbody>
                </table>
            </td>
            </tr>
            <tr>
            <td width="100%" style={{padding: 0}}>
                <table width="100%" cellPadding={0} cellSpacing={0}>
                <tbody>
                    <tr>
                        <td className="small-font" width="50%" valign="top">
                            <h5 style={{margin: '5px 0 0'}}>Notes</h5>
                            <ul style={{margin: 0}}>
                            <li>
                                Excess amount is denoted by (+) sign
                            </li>
                            <li>
                                Deficit amount is denoted by (-) sign
                            </li>
                            <li>
                                Comments field lists the relevant reason for excess/deficit and whether the deficit has been paid in cash by teller
                            </li>
                            <li>
                                Receipt of Cash Deposit from Box Excess Account must be attached in case of excess
                            </li>
                            <li>
                                Receipt of Cash Payment to Box Deficit Account of the relevant teller must be attached in case of deficit
                            </li>
                            <li>
                                Any detected excess/deficit in the system should reported immediately without delay
                            </li>
                            <li>
                                The cash count report along with the receipt of any cash deposit/payment is emailed to: <br></br> {props.data.reportCCEmailIDs}
                            </li>
                            </ul>
                        </td>
                        <td className="small-font text-rtl" valign="top">
                            <h5 style={{margin: '5px 0 0'}}>الملاحظات</h5>
                            <ul style={{margin: 0}}>
                            <li>
                                يؤشر مبلغ الفائض بالإشارة الموجبة (+)
                            </li>
                            <li>
                                يؤشر مبلغ العجز بالإشارة السالبة (-)
                            </li>
                            <li>
                                حقل الملاحظات  يبين أي معلومات ذات صلة بسبب الفائض أو العجز. وكذلك تتم الإشارة في حال تعويض المبلغ نقداً من قبل أمين الصندوق
                            </li>
                            <li>
                                يرفق وصل القبض النقدي لصالح حساب الفائض في الصندوق
                            </li>
                            <li>
                                يرفق وصل الصرف النقدي على حساب العجز في الصندوق لأمين الصندوق المعني
                            </li>
                            <li>
                                لا يتم تأجيل تقييد الفائض أو العجز في النظام. يتوجب التقييد حال اكتشافها والتحقق منها
                            </li>
                            <li>
                                يرسل التقرير مع وصل القبض النقدي أو وصل الدفع النقدي عبر البريد الإلكتروني إلى: <br></br> {props.data.reportCCEmailIDs}
                            </li>
                            </ul>
                        </td>
                    </tr>
                </tbody></table>
            </td>
            </tr>
            <tr>
            <td width="100%" style={{padding: 0}}>
                <table width="100%" cellPadding={0} cellSpacing={0}>
                <tbody>
                    <tr className="rtl">
                        {props.data.deskNo > 0 && 
                            <>
                                <td>
                                    <div className="signature text-center" style={{height: '60px'}} />
                                    توقيع أمين الصندوق Signature of Teller
                                </td>
                                <td>
                                    <div className="signature text-center" style={{height: '60px'}} />
                                    توقيع مدير الفرع Signature of Manager
                                </td>
                            </>                      
                        } 
                        {props.data.deskNo == 0 && 
                            <>
                                <td>
                                    <div className="signature text-center" style={{height: '60px'}} />
                                    مدير الفرع Signature of Branch Manager
                                </td>
                                <td>
                                    <div className="signature text-center" style={{height: '60px'}} />
                                    المدقق Signature of Auditor   
                                </td>
                            </>                      
                        }     
                    </tr>
                </tbody></table>
            </td>
            </tr>
        </tbody>
    </table>
    );
}

export { Header, Signature, CashReconciliationListInfo };