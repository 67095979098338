import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Header, Signature, SenderInfo, RefundFinancialInfo } from "./ReceiptComponents";

export default class RefundCancellationReceipt extends Component {
	isBankCopy = false;
	cssLoaded = false;
	constructor(props) {
		super(props);
		this.isBankCopy = props.isBankCopy;
		this.reportData = props.result;
        // this.reportData.isSendReceipt = true;
        this.reportData.isRefundReceipt = true;
		this.isReprint = props.isReprint;
        //console.log(this.reportData);
	}
	render() {
		if (this.cssLoaded === false) {
			this.cssLoaded = true;
			import("./Remittance.css");
		}
		return (
			<div className="content">
                    <table width="100%">
                        <tbody>
                            <tr>
                                <td width="100%" className="p-0 header-space"></td>
                            </tr>
                        </tbody>
                    </table>
                    <Header data={this.reportData} isReprint={this.isReprint}/>
					
                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="53%">
                                <table width="100%" cellPadding="0" cellSpacing="0">
                                    <tbody>
                                        <SenderInfo data={this.reportData} />
                                        {/* <BenificiaryInfo data={this.reportData} /> */}
                                        {/* <OtherInfo data={this.reportData} /> */}
                                    </tbody>
                                </table>
                            </td>
                            <td width="2%" align="center" style={{ position: "relative" }}><p className="devider"></p></td>
                            <td width="45%" valign="top">
                                <RefundFinancialInfo data={this.reportData} />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <Signature data={this.reportData} />
            </div>
		);
	}
}