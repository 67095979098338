import React, { Component } from "react";
import AgentRequest from "../components/Exchange/AgentRequest";
import querySearch from "stringquery";

import { getAgentExchangeRequest } from "../services/ReportService";

export default class AgentExchangeRequestReceipt extends Component {
	constructor() {
		super();
		this.state = { reportData: null };
	}
	componentDidMount() {
		const query = querySearch(window.location.search);
		getAgentExchangeRequest(query.tid, query.lang).then((res) => {
			this.setState({ reportData: res.data, urldata: query.v });
			document.title = "Agent_Exchange_Request_Receipt_" + res.data.transactionNumber;
		});
	}
	render() {
		return this.state.reportData ? (
			<div style={{ height: "100%" }}>
				<AgentRequest
					result={this.state.reportData}
					isBankCopy={false}
					copyPrint={this.state.urldata}
				/>
				<AgentRequest result={this.state.reportData} isBankCopy={true} />
			</div>
		) : (
			<div></div>
		);
	}
}