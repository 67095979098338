import React, { Component } from "react";
import { Header, Signature, FooterInfo } from "./HeaderComponents";

export default class Receipt extends Component {
	cssLoaded = false;
	constructor(props) {
		super(props);
		this.reportData = props.result;
		this.copyPrint = props.copyPrint;
	}
	render() {
		if (this.cssLoaded === false) {
			this.cssLoaded = true;
			import("./Exchange.css");
		}

		let requestNumber = "";
		
		if (this.reportData.requestNumber != '') {
			requestNumber = 
			<tr>
				<td>Request Number/ الرقم الطلب :</td>
				<td>
					<span>{this.reportData.requestNumber}</span>
				</td>
			</tr>;
		}

		return (
			<div className='content'>
				<table width='100%'>
					<tbody>
						<tr>
							<td width='100%' className='p-0 header-space'></td>
						</tr>
					</tbody>
				</table>
				<Header data={this.reportData} iscopy={this.copyPrint} />

				<table width='100%' className='border-0'>
					<tbody>
						<tr>
							<td width='100%'>
								<table width='100%' cellPadding='5' className='user-detail'>
									<thead>
										<tr>
											<th width='5%'>#</th>
											<th>Currency/العملة</th>
											<th>FCY Amount/المبلغ بالعملة الأجنبية</th>
											<th>Exchange Rate/سعر الصرف</th>
											<th className='text-right'>
												LCY Amount/العملةالمحلية
											</th>
										</tr>
									</thead>
									<tbody>
										{this.reportData.currencyExDetailReportResponses.map(
											(item, index) => (
												<tr key={index}>
													<td>{index + 1}</td>
													<td>{item.forexCurrencyCode}</td>
													<td>{item.forexCurrencyText}</td>
													<td>{item.inverseRateText} / {item.rateText}</td>
													<td className='right'>
														{this.reportData.baseCurrencyCode} {item.totalText}
													</td>
												</tr>
											)
										)}
									</tbody>
								</table>
							</td>
						</tr>
						<tr>
							<td width='100%' className='padding-0'>
								<table width='100%'>
									<tbody>
										<tr>
											<td width='100%' height={10} colSpan={3}></td>
										</tr>
										<tr>
											<td align='left' className='border-0' colSpan={2} width='50%'>
											</td>
											
											<td align='left' className='right'>
												<table width='100%' className='totals'>
													<tbody>
														{this.reportData.serviceFeeBreakdownResponses.map(
															(item, index) => (
																<tr key={index}>
																	<td></td>
																	<td width='40%' className='text-right'>
																		{item.feeLabel} :
																	</td>
																	<td width='20%'>
																		<span>{item.amountText}</span>
																	</td>
																</tr>
															)
														)}
														<tr>
															<td></td>
															<td align="left" style={{ border: "0 !importamt" }}>Rounding :</td>
															<td>
																<span>{this.reportData.rounding}</span>
															</td>
														</tr>
														<tr>
															<td></td>
															<td align="left">Total :</td>
															<td>
																<span>
																	{this.reportData.baseCurrencyCode}{" "}
																	{this.reportData.totalText}{" "}
																</span>
															</td>
														</tr>
													</tbody>
													<tfoot>
														<tr>
															<td style={{ borderTop: "0 !importamt" }}></td>
															<td
																width=''
																colSpan='2'
																style={{
																	border: "1px solid",
																	padding: 0,
																	whiteSpace: "nowrap",
																	unicodeBidi:"plaintext",
																	textAlign:"start"
																}}
															>
																{this.reportData.totalInWord}
															</td>
														</tr>
													</tfoot>
												</table>
											</td>
										</tr>
										<tr>
											<td align='left' className='border-0' width='35%'>
												<table width='100%'>
													<tbody>
														<tr style={{lineHeight:"11px"}} height='12'>
															<td>Customer/ الزبون :</td>
															<td>
																<span>{this.reportData.customerName}</span>
															</td>
														</tr>
														<tr>
															<td>Phone/ هاتف :</td>
															<td>
																<span>{this.reportData.phone}</span>
															</td>
														</tr>
														<tr height='40'>
															<td>Signature/التوقيع :</td>
														</tr>
													</tbody>
												</table>
											</td>
											<td className='border-0' width='35%'></td>
											<td align='left' className='border-0'>
												<table width='100%'>
													<tbody>
														<tr>
															<td>{this.reportData.deskName} Cashier/ أمين الصندوق :</td>
															<td>
																<span>{this.reportData.cashierName}</span>
															</td>
														</tr>
														{requestNumber}
														<tr height='40'>
															<td>Signature/التوقيع :</td>
															<td>
																<span
																	style={{ textDecoration: "underline" }}
																></span>
															</td>
														</tr>
													</tbody>
												</table>
											</td>
											
										</tr>
									</tbody>
								</table>
							</td>
						</tr>
					</tbody>
				</table>

				<table width='100%' className='footer' style={{ borderTop: 0 }}>
					<tbody>
						<tr>
							<td width='100%' className='p-0 footer-space'></td>
						</tr>
						<tr>
							<td width='100%' className='p-0' height='5'></td>
						</tr>
					</tbody>
				</table>
			</div>
		);
	}
}
