import React, { Component } from "react";
import {
	Header,
	Signature,
	SenderInfo,
	BenificiaryInfo,
	OtherInfo,
	FinancialInfo,
} from "./ReceiptComponents";
import "./Remittance.css";

export default class ImportRemittanceReceipt extends Component {
	constructor(props) {
		super(props);
		this.isBankCopy = props.isBankCopy;
		this.reportData = props.result;
		this.reportData.isImportRemittanceReceipt = true;
		this.showConsent = props.result.showConsent;
		document.title = "Import_Remittances_Receipt_" + props.result.transactionNumber;
	}

	render() {
		const consent = this.showConsent ? (
			<p style={{ fontSize: "10px", marginBottom: "0" }}>
				I consent to receive communications related to this transaction
			</p>
		) : null;

		return (
			<div className='content'>
				<table width='100%'>
					<tbody>
						<tr>
							<td width='100%' className='p-0 header-space'></td>
						</tr>
					</tbody>
				</table>
				<Header data={this.reportData} isReprint={this.isReprint} />

				<table width='100%'>
					<tbody>
						<tr>
							<td width='55%' valign='top'>
								<table width='100%' cellPadding='0' cellSpacing='0'>
									<tbody>
										<SenderInfo data={this.reportData.remittanceInfo} />
									</tbody>
								</table>
							</td>
							<td width='1%' align='center' style={{ position: "relative" }}>
								<p className='devider'></p>
							</td>
							<td width='44%' valign='top'>
								<table width='100%'>
									<tr>
										<td width="100%" className="p-0">
											<h3>
												<span className="w-50">Financial Info</span>
												<span className="w-50 text-right">المعلومات المالية</span>
											</h3>
										</td>
									</tr>
									<tr>
										<td width="100%" className="p-0">
											<table width="100%" cellPadding="0" cellSpacing="0" className="financial-info">
												<tbody>
													<tr>
														<td align="left" className="valign-middle"><p>Remittance Amount:</p></td>
														<td align="left" className="valign-middle">{this.reportData.sendCurrencyCode}</td>
														<td align="right" className="valign-bottom" style={{ fontSize: '11px' }}><b>{this.reportData.sendAmount}</b></td>
														<td align="right" className="valign-bottom">:مبلغ الحوالة</td>
													</tr>
													<tr>
														<td align="left" className="valign-middle"><p>Total Fee:</p></td>
														<td align="left" className="valign-middle">{this.reportData.feeCurrencyCode}</td>
														<td align="right" className="valign-bottom" style={{ fontSize: '11px' }}><b>{this.reportData.feeAmount}</b></td>
														<td align="right" className="valign-bottom">:الرسوم إجمالي</td>
													</tr>
													<tr></tr>
													<tr></tr>
												</tbody>
											</table>
										</td>
									</tr>
								</table>
							</td>
						</tr>
					</tbody>
				</table>
				<Signature data={this.reportData} />
				{consent}
			</div>
		);
	}
}
