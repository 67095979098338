import React, { Component } from 'react';
//import SendReceipt from '../components/Remittance/SendReceipt';
import CashReconciliationReport from '../components/CashReconciliation/CashReconciliationReport';
import { getInternalCashReconciliationReportData } from '../services/ReportService';

export default class InternalCashReconciliationReport extends Component {

  constructor(){
    super();
    this.language = 'en';
    this.tid = window.location.pathname.split('/')[2];
    this.state = { reportData: null };
    this.isReprintData = false;
    var reprint = window.location.search; 
    if (reprint == '') {
        this.isReprintData = false;
    }
    else {
      this.isReprintData = true;
    }
  }
  componentDidMount() {

    getInternalCashReconciliationReportData(this.tid, this.language).then((res) => {
      this.setState({reportData : res.data});
      document.title = "Reconciliation_Report_" + res.data.transactionNo;
    });
  }
  render() {
    return (
      this.state.reportData ?
        <div>
          <CashReconciliationReport result={this.state.reportData} isReprint={this.isReprintData}/>
          
        </div>
        : <div></div>
    );
  }
}
