import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import {
	DecimalToFixed,
	FormatAmount,
	TrimTime,
	FormatTransactionNumber,
} from "../../utils/helper";
import Barcode from "react-barcode";

const tdStyle = { direction: "ltr" };

function Header(props) {
	let headerText;
	let headerSubText;
	let branchTitleEng = "Branch:";
	let branchTitleAr = ":الفرع";
	let barcode = <Barcode
		value={props.data.transactionNumber}
		height={25}
		width={1}
		fontSize={10}
		margin={5}
	/>;

	if (props.data.serviceId == 1) {
		headerText = <b> Receipt / إيصال </b>;
		headerSubText = <b> Buy Currency / شراء العملات </b>;
	} else if (props.data.serviceId == 4) {
		headerText = <b> Receipt / إيصال </b>;
		headerSubText = <b> Sell Currency / بيع العملة </b>;
	} else if (props.data.serviceId == 20) {
		headerText = <b> Foreign Currency Buy / شراء عملة أجنبية </b>;
		headerSubText = <b>Account / حساب </b>;
		branchTitleEng = "Agent:";
		branchTitleAr = ":الوكيل";
	} else if (props.data.serviceId == 21) {
		headerText = <b> Foreign Currency Sell / بيع عملة أجنبية </b>;
		headerSubText = <b>Account / حساب </b>;
		branchTitleEng = "Agent:";
		branchTitleAr = ":الوكيل";
	} else if (props.data.serviceId == 22) {
		headerText = <b> Buy Exchange Request / طلب صرف عملة شراء </b>;
		headerSubText = <b> Account / حساب </b>;
		branchTitleEng = "Agent:";
		branchTitleAr = ":الوكيل";
		// barcode = "";
	} else if (props.data.serviceId == 24) {
		headerText = <b> Sell Exchange Request / طلب صرف عملة بيع </b>;
		headerSubText = <b> Account / حساب </b>;
		branchTitleEng = "Agent:";
		branchTitleAr = ":الوكيل";
		// barcode = "";
	}
	else if (props.data.serviceId == 27) {
		headerText = <b> CBI Auction Request /طلب مزاد المركزي </b>;
		headerSubText = <b> Sell USD / بيع الدولار </b>;
	}

	else if (props.data.serviceId == 38) {
		headerText = <b> Receipt / إيصال </b>;
		headerSubText = <b> Bulk Buy Currency / شراء العملة بالجملة </b>;
	}
	else if (props.data.serviceId == 39) {
		headerText = <b> Receipt / إيصال </b>;
		headerSubText = <b> Bulk Sell Currency /  بيع العملة بالجملة </b>;
	}
	else if (props.data.serviceId == 42) {
		headerText = <b> Receipt / إيصال </b>;
		headerSubText = <b> Head Office Buy from Branch / شراء العملة من فرع </b>;
	}
	else if (props.data.serviceId == 43) {
		headerText = <b> Receipt / إيصال </b>;
		headerSubText = <b> Head Office Sell to Branch / بيع العملة إلى فرع </b>;
	}

	let ReprintCode = <td width='35%' align='right' valign='middle'></td>;

	if (props.iscopy) {
		ReprintCode = (
			<td width='30%' align='right' valign='middle'>
				<p>
					<span className='w-25 text-start' style={{fontWeight:'normal'}}>Reprint:</span>
					<span className='w-50 text-center' style={{fontWeight:'normal'}}> {props.data.currentDate}</span>
					<span className='w-25 text-end' style={{fontWeight:'normal'}}>:إعادة طباعة</span>
				</p>
			</td>
		);
	}

	return (
		<table width='100%' className='header'>
			<tbody>
				<tr>
					<td width='35%' align='left'>
						<p className='mb-0'>
							<span className='w-75 d-inlineblock valign-middle'>
								{barcode}
							</span>
						</p>
					</td>
					<td align='center' valign='top'>
						<h4 style={{fontSize: "12px", marginTop: "10px", lineHeight: "1.4", marginBottom: "0" }}>{headerText}</h4>
						<h4 style={{  marginTop: "0",lineHeight:'1.4' }}>{headerSubText}</h4>
					</td>
					{ReprintCode}
				</tr>
				<tr>
					<td width='30%' align='left'>
						<p>
							<span className='w-25 text-start'>TRN No:</span>
							<span className='w-50 text-center'>
								<b> {FormatTransactionNumber(props.data.transactionNumber)} </b>
							</span>
							<span className='w-25 text-end'>:العدد</span>
						</p>
						<p>
							<span className='w-25 text-start'>Date:</span>
							<span className='w-50 text-center'>
								<b> {props.data.transactionDate} </b>
							</span>
							<span className='w-25 text-end'>:التاريخ</span>
						</p>
					</td>
					<td align='center' valign='top'></td>
					<td width='35%' align='right' valign='bottom'>
						<p>
							<span className='w-25 text-start'>{branchTitleEng}</span>
							<span className='w-50 text-center'>
								<b> {props.data.branchName} </b>
							</span>
							<span className='w-25 text-end'>{branchTitleAr}</span>
						</p>
						<p>
							<span className='w-25 text-start'>Phone:</span>
							<span className='w-50 text-center'>
								<b> {props.data.branchPhone} </b>
							</span>
							<span className='w-25 text-end'>:هاتف</span>
						</p>
					</td>
				</tr>
			</tbody>
		</table>
	);
}

function Signature(props) {
	return <div></div>;
}

function FooterInfo(props) {
	return <div></div>;
}

export { Header, Signature, FooterInfo };
